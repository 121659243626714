angular.module('mapleLeafApp')
    .directive('scrollHeader', ["$window", "$location", "$rootScope", "hdImage", scrollHeader]);

/** Directive to change the header background in responsive **/
function scrollHeader($window, $location, $rootScope, hdImage) {

    function link(scope, elem, attrs) {
        var headerLimit, windowTopOffset, scrollFunction, checkWindow, windowWidth, askOnce = false;

        $('header').addClass('dark');
        $('.stripes').addClass('dark');
        $('.logo-header').attr({
            'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
        });

        var url = $location.path().split('/');
        url = url[1];

        var actualTheme = "";

        $rootScope.resetAskOne = function () {
            askOnce = false;
        };

        checkWindow = function () {
            if ($window.innerWidth <= 767 && $location.path() == '/') {
                setTimeout(function () {
                    windowTopOffset = $window.pageYOffset + $('header').height();

                    var home = $('#home').offset();
                    var about = $('#about').offset();
                    var collections = $('#collections').offset();
                    var tracking = $('#tracking').offset();
                    var locator = $('#locator').offset();
                    var engagement = $('#engagement').offset();

                    if ($window.pageYOffset + $window.innerHeight >= locator.top && !askOnce) {
                        var coords = {
                            lat: 57.8230229,
                            lng: -102.6326396
                        };
                        $rootScope.initMap(coords);
                        askOnce = true;
                    }

                    if ($rootScope.firstVersion) {
                        if (windowTopOffset >= home.top && windowTopOffset <= tracking.top) {
                            actualTheme = $('#home')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $(elem).addClass(actualTheme);
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                                });
                            }
                        } else if (windowTopOffset >= tracking.top && windowTopOffset <= locator.top) {
                            actualTheme = $('#tracking')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $(elem).addClass(actualTheme);
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-whiteletters-244x69.png')
                                });

                            }
                        } else if (windowTopOffset >= locator.top) {
                            actualTheme = $('#locator')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $(elem).addClass(actualTheme);
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                                });

                            }
                        }
                    } else {
                        if (windowTopOffset >= home.top && windowTopOffset <= collections.top) {
                            actualTheme = $('#home')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $(elem).addClass(actualTheme);
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                                });
                            }
                        } else if (windowTopOffset >= collections.top && windowTopOffset <= engagement.top) {
                            actualTheme = $('#collections')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $(elem).addClass(actualTheme);
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-whiteletters-244x69.png')
                                });
                            }
                        } else if (windowTopOffset >= engagement.top && windowTopOffset <= tracking.top) {
                            actualTheme = $('#engagement')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $(elem).addClass(actualTheme);
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                                });

                            }
                        } else if (windowTopOffset >= tracking.top && windowTopOffset <= about.top) {
                            actualTheme = $('#tracking')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $(elem).addClass(actualTheme);
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-whiteletters-244x69.png')
                                });

                            }
                        } else if (windowTopOffset >= about.top && windowTopOffset <= locator.top) {
                            actualTheme = $('#about')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $(elem).addClass(actualTheme);
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                                });

                            }
                        } else if (windowTopOffset >= locator.top) {
                            actualTheme = $('#locator')[0].attributes['color'].value;

                            if (!angular.element(elem).hasClass(actualTheme)) {
                                $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                                $(elem).addClass(actualTheme);
                                $('.stripes').removeClass('light light-rgba dark-rgba dark');
                                $('.stripes').addClass(actualTheme);
                                $('.logo-header').attr({
                                    'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                                });

                            }
                        }
                    }
                }, 100);
            } else if ($window.innerWidth <= 769 && url == 'about') {

                var sections = $('section');
                windowTopOffset = $window.pageYOffset + $('header').height();


                for (var i = 0; i < sections.length; i++) {

                    var top = $(sections[i]).offset();
                    var height = $(sections[i]).height();

                    if (windowTopOffset >= top.top && top.top > -height) {

                        actualTheme = $(sections[i])[0].attributes['bgrd'].value;

                        if (!angular.element(elem).hasClass(actualTheme)) {
                            $(elem).removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                            $(elem).addClass(actualTheme);
                            $('.stripes').removeClass('light light-rgba dark-rgba dark light-transparent dark-transparent');
                            $('.stripes').addClass(actualTheme);
                            $('.logo-header').attr({
                                'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                            });

                        }
                    }
                }
            }
        };

        angular.element($window).bind('scroll DOMMouseScroll mousewheel touchmove onmousewheel', function () {
            if ($location.path() == '/') {
                checkWindow();
            }
        });
    }

    return {
        restrict: 'A',
        link: link
    }

}