angular.module('mapleLeafApp')
    .controller('trackingController', ["$scope", "$rootScope", "$localStorage", "$mdToast", "vcRecaptchaService", "DiamondTracking", "$cookies", "coronaURL", "hdImage", "recaptchaKey", trackingController]);

function trackingController($scope, $rootScope, $localStorage, $mdToast, vcRecaptchaService, DiamondTracking, $cookies, coronaURL, hdImage, recaptchaKey) {

    $scope.showLoader();

    $scope.$watch(function () {
        return $cookies.get("siteLanguage");
    }, function (newVal, oldVal) {
        getTexts();
    });

    $scope.$on('$viewContentLoaded', function () {
        $('header').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
        $('header').addClass('light-rgba tracking');
        $('.logo-header').attr({
            'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-whiteletters-244x69.png')
        });
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
        }
    });

    $scope.$on('$routeChangeStart', function () {
        $('header').removeClass('light-rgba tracking');
    });

    /** Tracking controller variables **/
    $scope.coronaURL = coronaURL;
    $scope.recaptchaKey = recaptchaKey;
    $scope.response = null;
    $scope.widgetId = null;
    $scope.diamondInfo = "";
    $scope.trackingNumber = "";
    $rootScope.trackLoader = false;
    $scope.diamondPartial = '/partials/trackingForm.html';

    (function getTexts() {
        $scope.trackingTexts = $localStorage.siteTexts.tracking;
        $scope.trackingTexts.inputLabelError = '';
        $scope.trackingTexts.inputLabel = $scope.trackingTexts.trackTitle;

        if ($cookies.get("siteLanguage") == "en-ca" || $cookies.get("siteLanguage") == "en-us") {
            $scope.recaptchaLanguage = "en";
        } else {
            $scope.recaptchaLanguage = "fr-CA";
        }

    })();

    /**Validations for the repcatcha**/
    $scope.setResponse = function (response, trackingNumber) {
        $scope.response = response;
        $scope.sendTrackingNumber(null, trackingNumber, response);
    };

    $scope.setWidgetId = function (widgetId) {
        $scope.widgetId = widgetId;
    };

    $scope.cbExpiration = function () {
        vcRecaptchaService.reload($scope.widgetId);
        $scope.response = null;
    };

    /**Function to search for a diamond**/
    $scope.sendTrackingNumber = function (ev, trackingNumber, reCaptchaResponse) {

        if (trackingNumber.length <= 0 || trackingNumber == "") {
            $mdToast.show(
                $mdToast.simple()
                    .textContent($scope.trackingTexts.enterNumber)
                    .position('bottom right')
                    .toastClass('md-warn')
            );
            return false;
        }

        if (vcRecaptchaService.getResponse($scope.widgetId) != '') {

            vcRecaptchaService.reload($scope.widgetId);

            $rootScope.trackLoader = true;

            var params = {
                "language": $cookies.get("siteLanguage"),
                "id": trackingNumber,
                "recaptcha_sitekey": recaptchaKey,
                "recaptcha_response_field": reCaptchaResponse
            };

            DiamondTracking.sendNumber(params)
                .then(function successCallback(response) {
                    $scope.trackingTexts.inputLabelError = "";
                    $scope.trackingTexts.inputLabel = $scope.trackingTexts.anotherTitle;
                    $scope.diamondInfo = response.data;
                    $rootScope.trackLoader = false;
                    $scope.diamondPartial = '/partials/trackingResult.html';
                    vcRecaptchaService.reload($scope.widgetId);
                }, function errorCallback(response) {
                    $scope.trackingTexts.inputLabelError = response.statusText;
                    $scope.trackingTexts.inputLabel = $scope.trackingTexts.anotherTitle;
                    $rootScope.trackLoader = false;
                    vcRecaptchaService.reload($scope.widgetId);
                });

        } else {
            $mdToast.show(
                $mdToast.simple()
                    .textContent($scope.trackingTexts.recaptchaText)
                    .position('bottom right')
                    .toastClass('md-warn')
            );
        }
    };

}
