angular.module('mapleLeafApp')
    .controller('pieceDetailController', ["$scope", "$rootScope", "$window", "$location", "$routeParams", "$localStorage", "$cookies", "$timeout", "$mdDialog", "$mdToast", "coronaURL", "Items", "Collections", "hdImage", "$sce", pieceDetailController]);

function pieceDetailController($scope, $rootScope, $window, $location, $routeParams, $localStorage, $cookies, $timeout, $mdDialog, $mdToast, coronaURL, Items, Collections, hdImage, $sce) {

    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        $('header').addClass('dark');
        $('header').addClass('absolute');
        $('.stripes').addClass('dark');
        $('.languages').addClass('dark');
        $('.logo-header').attr({
            'src': '/images/maple-leaf-diamonds-logo-254x71.png'
        });
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
        }
    });

    $scope.$on('$routeChangeStart', function (e, next, current) {
        if (next.keys.length > 0) {
            if (next.keys[0].name != "collection" && next.keys[0].name != "piece") {
                delete $localStorage.itemsList;
                delete $localStorage.filters;
            }
        }
        angular.element($window).off('resize', function () {
            angular.noop();
        });
        if (angular.isDefined($cookies.get("selectedDealer")) || $cookies.get("selectedDealer") != "") {
            $cookies.put("selectedDealer", "");
        }
    });

    $scope.coronaURL = coronaURL;
    $scope.currentURL = $location.absUrl();
    $scope.noResults = false;
    $scope.hidePreviousArrows = true;
    $scope.hideNextArrows = true;
    $scope.previousPiecePath = "";
    $scope.nextPiecePath = "";

    (function getTexts() {
        $scope.mine = $routeParams.mine;
        $scope.texts = $localStorage.siteTexts.item;
        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };
    })();

    $scope.pieceViews = [];
    $scope.actualSlideItem = [];

    function getItemDetails() {

        var params;

        if (angular.isUndefined($routeParams.collection)) {
            params = {
                "language": $cookies.get("siteLanguage"),
                "id": $routeParams.piece,
                "device": $cookies.get("device")
            };
        } else {
            params = {
                "language": $cookies.get("siteLanguage"),
                "id": $routeParams.piece,
                "device": $cookies.get("device"),
                "collectionId": $routeParams.collection
            };
        }

        Items.getItemDetails(params)
            .then(function (response) {

                $scope.itemDetail = response.data;
                if (angular.isDefined($localStorage.itemsList)) {
                    ifItemExist($scope.itemDetail);
                }

                var description = '';
                var oneVideo = false;

                if ($scope.itemDetail.description1 != undefined) {
                    description += $scope.itemDetail.description1;
                }

                if ($scope.itemDetail.description2 != undefined) {
                    description = ' ' + $scope.itemDetail.description2;
                }

                if ($scope.itemDetail.style != undefined) {
                    description = ' ' + $scope.itemDetail.style;
                }

                if ($scope.itemDetail.cataloque != undefined) {
                    description = ' ' + $scope.itemDetail.cataloque;
                }

                $rootScope.social = {
                    url: $location.absUrl(),
                    title: $scope.itemDetail.title1 + " " + $scope.itemDetail.description1 + " " + $scope.itemDetail.description2,
                    description: description,
                    image: coronaURL + $scope.itemDetail.image
                };

                if (response.data.video != undefined) {
                    oneVideo = true;
                    var temp = {
                        id: 0,
                        path: coronaURL + response.data.video.large,
                        title: "Video",
                        type: "video"
                    };
                    $scope.pieceViews.push(temp);
                }

                var tempImg = {
                    id: $scope.pieceViews.length,
                    path: coronaURL + response.data.image,
                    title: "Earrings",
                    type: "image"
                }

                $scope.pieceViews.push(tempImg);

                if (response.data.images != undefined) {
                    for (var index = 0; index < response.data.images.length; index++) {
                        var element = response.data.images[index];
                        var cont = $scope.pieceViews.length;
                        var temp = {
                            id: cont,
                            path: coronaURL + element.image,
                            title: "Earrings",
                            type: "image"
                        };
                        cont++;
                        $scope.pieceViews.push(temp);
                    }
                }

                $scope.principalSlickConfig = {
                    enabled: true,
                    autoplay: false,
                    arrows: false,
                    draggable: true,
                    infinite: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initOnload: true,
                    asNavFor: "#slider-nav",
                    fade: true,
                    event: {
                        beforeChange: function (event, slick, currentSlide, nextSlide) {
                            event.stopPropagation();
                            if ($scope.pieceViews[nextSlide].type == "video") {
                                currentSlide -= 1;
                                var videoId = "theVideo" + currentSlide;
                                var myVideo = document.getElementById(videoId);
                                if (myVideo.tagName === "VIDEO") {
                                    myVideo.pause();
                                }
                            }
                        },
                        afterChange: function (event, slick, currentSlide, nextSlide) {
                            event.stopPropagation();
                            if ($scope.pieceViews[currentSlide].type == "video") {
                                var videoId = "theVideo" + currentSlide;
                                var myVideo = document.getElementById(videoId);
                                if (myVideo.tagName === "VIDEO") {
                                    myVideo.play();
                                }
                            }
                        }
                    }
                };

                var slidesNumber = 9,
                    eneableArrows = true,
                    infiniteSlider = true;
                $scope.actualSlideItem = {};

                function verifyScreenSize() {
                    if ($window.innerWidth == 768) {
                        slidesNumber = 7;
                        eneableArrows = false;
                    } else if ($window.innerWidth > 767 && $window.innerWidth <= 992) {
                        slidesNumber = 7;
                        eneableArrows = false;
                    } else if ($window.innerWidth > 992 && $window.innerWidth <= 1024) {
                        slidesNumber = 7;
                        eneableArrows = false;
                    } else if ($window.innerWidth <= 767) {
                        slidesNumber = 7;
                        eneableArrows = false;
                        infiniteSlider = false;
                    }
                };
                verifyScreenSize();

                $scope.thumbnailSlickConfig = {
                    enabled: true,
                    autoplay: false,
                    draggable: true,
                    arrows: eneableArrows,
                    infinite: infiniteSlider,
                    dots: false,
                    slidesToShow: slidesNumber,
                    initOnload: true,
                    asNavFor: "#slidePieceDetail",
                    focusOnSelect: true,
                    method: {},
                    event: {
                        init: function (slick) {
                            $scope.actualSlideItem = $scope.pieceViews[0];

                            if ($scope.pieceViews.length > 0) {
                                if ($scope.pieceViews[0].type == "video") {
                                    var videoId = "theVideo" + 0;
                                    var myVideo = document.getElementById(videoId);

                                    myVideo.play();

                                }
                            }

                        },
                        beforeChange: function (event, slick, currentSlide, nextSlide) {
                            if ($scope.pieceViews[nextSlide].type == "video") {
                                var videoId = "theVideo" + currentSlide;
                                var myVideo = document.getElementById(videoId);

                                if (myVideo.paused) {
                                    myVideo.play();
                                } else {
                                    myVideo.pause();
                                }
                            } else {
                                var videoId = "theVideo" + currentSlide;
                                var myVideo = document.getElementById(videoId);
                                myVideo.pause();
                            }
                        },
                        afterChange: function (event, slick, currentSlide, nextSlide) {
                            $scope.actualSlideItem = $scope.pieceViews[currentSlide];
                            if ($scope.pieceViews[currentSlide].type == "video") {
                                var videoId = "theVideo" + currentSlide;
                                var myVideo = document.getElementById(videoId);
                                if (myVideo.paused) {
                                    myVideo.play();
                                } else {
                                    myVideo.pause();
                                }
                            }
                        }
                    }
                };

                angular.element($window).bind('resize', function () {
                    verifyScreenSize();
                });

            }, function (response) {
                $scope.noResults = true;
                console.log(response);
            });
    };
    getItemDetails();

    function ifItemExist(currentPiece) {
        var items = $localStorage.itemsList;

        for (var index = 0; index < items.length; index++) {
            var element = items[index];
            if (currentPiece.id == element.id && currentPiece.collectionId == element.collectionId) {
                previousNextItems(currentPiece);
                return false;
            }
        }
        delete $localStorage.itemsList;
        delete $localStorage.filters;
    };

    $scope.showClon = false;
    $scope.clonItem = null

    $scope.setTempItem = function (piece) {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            if (piece.type != "video") {
                var sliderHeight = $('#slidePieceDetail .slick-current').height();
                var sliderWidth = $('#slidePieceDetail .slick-current').width();

                $('.clonedItem').css({
                    'height': sliderHeight + 'px',
                    'width': sliderWidth + 'px'
                });
                $scope.clonItem = piece;
                $scope.showClon = true;
            }
        }
    };

    $scope.removeTempItem = function () {
        $scope.clonItem = null;
        $scope.showClon = false;
    };

    var url = $location.path().split('/collections/');
    $scope.currenPath = url[1];

    $scope.backToCollection = function () {
        if ($localStorage.urlFilters != "" && angular.isDefined($localStorage.urlFilters)) {
            var url = $localStorage.urlFilters;
            $localStorage.urlFilters = "";
            $window.location.href = url;
        } else {
            $window.location.href = '/collections';
        }
    };

    $scope.openPopupSendHint = function (ev) {
        if ($scope.actualSlideItem.type == "video") {
            var videoId = "theVideo" + $scope.actualSlideItem.id;
            var myVideo = document.getElementById(videoId);
            if (!myVideo.paused) {
                myVideo.pause();
            }
        }

        $mdDialog.show({
            controller: sendHintController,
            templateUrl: '/partials/sendHintTemplate.tmpl.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            escapeToClose: false,
            locals: {
                texts: $scope.texts.sendHint,
                itemDetail: $scope.itemDetail,
                items: $scope.pieceViews,
                currentURL: $scope.currentURL,
                actualSlide: $scope.actualSlideItem
            }
        });
    };

    function sendHintController($scope, $mdDialog, itemDetail, items, currentURL, actualSlide, texts) {
        $scope.hintParams = {
            recipientName: '',
            recipientEmail: '',
            recipientPhone: '',
            name: '',
            email: '',
            phone: '',
            comments: ''
        };

        $scope.texts = texts;
        $scope.loader = false;
        $scope.itemDetail = itemDetail;
        $scope.currentURL = currentURL;
        $scope.item = items[1];
        $scope.year = new Date();
        $scope.year = $scope.year.getFullYear();
        $scope.imageUrl = coronaURL + itemDetail.image;

        $scope.cancelPopupHint = function () {
            if (actualSlide.type == "video") {
                var videoId = "theVideo" + actualSlide.id;
                var myVideo = document.getElementById(videoId);
                if (myVideo.paused) {
                    myVideo.play();
                }
            }

            $mdDialog.cancel();
        };

        $scope.sendHint = function (params) {

            if ($scope.hintParams.recipientName == '') {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.recipientNameError)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            if ($scope.hintParams.recipientEmail == '') {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.recipientEmailError)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            var emailPattern = /^.+@.+\..+$/;
            if (!emailPattern.test($scope.hintParams.recipientEmail)) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.recipientEmailWrongFormat)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            if ($scope.hintParams.name == '') {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.nameError)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            if ($scope.hintParams.email == '') {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.emailError)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            if (!emailPattern.test($scope.hintParams.email)) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.emailWrongFormat)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            var sendParams = {
                "language": $cookies.get("siteLanguage"),
                "senderName": params.name,
                "senderPhone": params.phone,
                "senderEmail": params.email,
                "recipientName": params.recipientName,
                "recipientEmail": params.recipientEmail,
                "recipientPhone": params.recipientPhone,
                "comments": params.comments,
                "referenceType": "Item",
                "referenceId": itemDetail.id,
                "collectionId": itemDetail.collectionId,
                "referenceURL": currentURL
            };

            $scope.loader = true;

            Items.sendHint(sendParams)
                .then(function (data) {
                    $scope.loader = false;

                    $mdToast.show(
                        $mdToast.simple()
                            .textContent($scope.texts.succesMsg + params.recipientName)
                            .toastClass('md-success')
                            .position('bottom right')
                    );
                    $scope.cancelPopupHint();
                }, function (data) {
                    $scope.loader = false;
                    $mdToast.show(
                        $mdToast.simple()
                            .textContent(data.statusText)
                            .toastClass('md-warn')
                            .position('bottom right')
                    );
                });
        };
    };

    $scope.selectDealer = function () {
        $cookies.put("locatorPiece", "/collections/" + $routeParams.collection + "/" + $routeParams.piece);
        $location.path('locator/');
    }

    if ($cookies.get("selectedDealer") != "" && $cookies.get("selectedDealer") != undefined) {
        $scope.$watch(function () {
            return $scope.itemDetail;
        }, function (newVal, oldVal) {
            if (angular.isDefined(newVal)) {
                $mdDialog.show({
                    controller: moreInfoController,
                    templateUrl: '/partials/moreInformation.tmpl.html',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    locals: {
                        dealerDetail: $cookies.get("selectedDealer"),
                        itemDetail: newVal,
                        currentURL: $scope.currentURL
                    }
                });
            }
        });
    }

    function moreInfoController($scope, $localStorage, $mdDialog, dealerDetail, itemDetail, currentURL) {

        $scope.moreInfoParams = {
            name: '',
            email: '',
            phone: '',
            comments: '',
        };

        $scope.texts = $localStorage.siteTexts.item.moreInfo;

        $scope.loader = false;

        $scope.cancelMoreInfo = function () {
            $cookies.put("selectedDealer", "");
            $mdDialog.cancel();
        };

        $scope.askMoreInfo = function (params) {

            if (params.name == '') {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.nameError)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            if (params.email == '') {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.emailError)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            var emailPattern = /^.+@.+\..+$/;
            if (!emailPattern.test(params.email)) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.emailWrongFormat)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            if (params.comments == '') {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($scope.texts.commentsError)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            }

            var sendParams = {
                "language": $cookies.get("siteLanguage"),
                "name": params.name,
                "phone": params.phone,
                "email": params.email,
                "dealerId": dealerDetail,
                "comments": params.comments,
                "referenceType": "Item",
                "referenceId": itemDetail.id,
                "collectionId": itemDetail.collectionId,
                "referenceURL": currentURL
            };

            $scope.loader = true;

            Items.moreInfo(sendParams)
                .then(function (data) {
                    $scope.loader = false;
                    $cookies.put("selectedDealer", "");
                    $mdToast.show(
                        $mdToast.simple()
                            .textContent($scope.texts.succesMsg)
                            .toastClass('md-success')
                            .position('bottom right')
                    );
                    $scope.cancelMoreInfo();
                }, function (data) {
                    $scope.loader = false;
                    $cookies.put("selectedDealer", "");
                    $mdToast.show(
                        $mdToast.simple()
                            .textContent(data.statusText)
                            .toastClass('md-warn')
                            .position('bottom right')
                    );
                });

        };

    }

    function previousNextItems(currentPiece) {
        var items = $localStorage.itemsList;
        var filters = $localStorage.filters;
        var next = null,
            previous = null;
        $scope.moreItems = [];

        for (var index = 0; index < items.length; index++) {
            var element = items[index];
            if (element.id == currentPiece.id && element.collectionId == currentPiece.collectionId) {
                if (index > 0) {
                    previous = items[index - 1];
                    $scope.hidePreviousArrows = false;
                }

                if (index == items.length - 1) {
                    if (items.length >= 21) {

                        moreItems(index + 1, filters);

                        $scope.$watch(function () {
                            return $scope.moreItems;
                        }, function (newVal, oldVal) {
                            if (newVal.length > 0) {
                                next = newVal[0];
                                for (var index = 0; index < newVal.length; index++) {
                                    items.push(newVal[index]);
                                }
                                $localStorage.itemsList = items;
                                setPreviousNextArrow(previous, next)
                            } else {
                                $scope.hideNextArrows = true;
                            }
                        });
                    }
                } else {
                    next = items[index + 1];
                }
                setPreviousNextArrow(previous, next);
                break;
            }
        }

    };

    function moreItems(index, filters) {
        var params = {
            "language": $cookies.get("siteLanguage"),
            "filters": filters,
            "search": "",
            "record_start": index + 1,
            "record_count": 21,
            "device": $cookies.get("device")
        };

        Collections.getItems(params)
            .then(function (response) {
                $scope.moreItems = response.data.items;
            }, function (response) {
                console.log(response);
            });
    }

    function setPreviousNextArrow(previous, next) {
        $scope.previousPiecePath = "";
        $scope.nextPiecePath = "";

        if (angular.isUndefined($routeParams.collection)) {
            if (previous != null) {
                $scope.previousPiecePath = "/piece/" + previous.id;
                $scope.hidePreviousArrows = false;
            }
            if (next != null) {
                $scope.nextPiecePath = "/piece/" + next.id;
                $scope.hideNextArrows = false;
            }
        } else {
            if (previous != null) {
                $scope.previousPiecePath = "/collections/" + previous.collectionId + "/" + previous.id;
                $scope.hidePreviousArrows = false;
            }
            if (next != null) {
                $scope.nextPiecePath = "/collections/" + next.collectionId + "/" + next.id;
                $scope.hideNextArrows = false;
            }
        }
    }

    $scope.setArrowsOffset = function () {
        $timeout(function () {
            $scope.offset = (($window.innerWidth - angular.element('body')[0].clientWidth) / 2) + 10;
        });
    };
    $scope.setArrowsOffset();

    angular.element($window).bind('resize', function () {
        $scope.setArrowsOffset();
    });

    function hideDownloadVideo() {
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

        if (isChrome || isOpera) {
            if (document.styleSheets) {
                var head = document.getElementsByTagName('head')[0];
                var styleSheet = document.createElement('style');
                styleSheet.innerHTML = 'video::-internal-media-controls-download-button { display: none; } video::-webkit-media-controls-enclosure { overflow: hidden; } video::-webkit-media-controls-panel { width: calc(100% + 30px) }';
                head.appendChild(styleSheet);
            }
        }
    }
    hideDownloadVideo();

    $scope.shareOverrideOGMeta = function (item) {
        if(checkLoginState()) {
            testAPI(itewm);
        } else {

        }
    }

    function statusChangeCallback(response) {
        // The response object is returned with a status field that lets the
        // app know the current login status of the person.
        // Full docs on the response object can be found in the documentation
        // for FB.getLoginStatus().
        if (response.status === 'connected') {
            // Logged into your app and Facebook.
            shareItem($scope.itemDetail);
        } else {
            FB.login(function(response) {
                shareItem($scope.itemDetail);
                // handle the response
              }, {scope: 'public_profile,email'});
            // The person is not logged into your app or we are unable to tell.
            // document.getElementById('status').innerHTML = 'Please log ' +
            //     'into this app.';
        }
    }

    // This function is called when someone finishes with the Login
    // Button.  See the onlogin handler attached to it in the sample
    // code below.
    $scope.shareOverrideOGMeta = function(item) {
        FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
        });
    }

    function shareItem(item) {
        var overrideLink = window.location.origin + "/piece/" + item.id;
        var overrideTitle = item.title1;
        var overrideDescription = item.description1 + " " + item.description2;
        var overrideImage = $scope.coronaURL + item.image;

        FB.ui({
            method: 'share_open_graph',
            action_type: 'og.shares',
            action_properties: JSON.stringify({
                object: {
                    'og:url': overrideLink,
                    'og:title': overrideTitle,
                    'og:description': overrideDescription,
                    'og:image': overrideImage
                }
            })
        },
            function (response) {
                console.log(response);
            });
    }

}
