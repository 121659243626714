angular.module('mapleLeafApp')
    .controller('menuController', ["$scope", "$rootScope", "$localStorage", "$route", "$location", "$window", "$cookies", "$timeout", menuController]);

function menuController($scope, $rootScope, $localStorage, $route, $location, $window, $cookies, $timeout) {

    $scope.$on('$routeChangeStart', function ($ev, current, previous) {
        angular.element('.phoneMenu').removeClass('open');
        $('html, body').removeClass('no-overflow');
    });

    $scope.$watch(function () {
        return $localStorage.siteTexts;
    }, function (newVal, oldVal) {
        getTexts(newVal);
    });

    function getTexts(texts) {
        if (angular.isDefined($localStorage.siteTexts)) {
            $scope.headerTexts = $localStorage.siteTexts.header;
        };
    };

    /** Function for the menu options **/
    $rootScope.headerLinks = function ($ev, url) {
        $ev.preventDefault();
        var path = url;

        if (url == "engagement") {
            $window.location.href = engagementURL;
        } else {

            if ($location.path() == '/') {
                if ($window.innerWidth < 768) {
                    $timeout(function () {
                        angular.element('.phoneMenu').removeClass('open');
                        $rootScope.scrollTo(path);
                    });
                } else {
                    $rootScope.goToSection(path);
                }
            } else {
                $location.search('collection', null);
                $location.search('category', null);
                $location.search('style', null);
                $location.search('shape', null);
                $timeout(function () {
                    $cookies.put("scrollID", path);
                    $location.path('/');
                });

            }

        }
    };

    $rootScope.toMinesMap = function () {
        $location.search('collection', null);
        $location.search('category', null);
        $location.search('style', null);
        $location.search('shape', null);
        $timeout(function () {
            $cookies.put("scrollID", 'exploration-of-canadian-diamonds');
            $location.path('/about/canadian-diamonds');
        });
    };

    $scope.isActive = function (path) {
        if ($route.current && $route.current.regexp) {
            return $route.current && $route.current.regexp.test(path);
        }
        return false;
    };

    $scope.changeLanguage = function (lang) {
        $cookies.put("siteLanguage", lang);
    };

    $scope.isSegment = function (path, segment) {

        if (segment == undefined) {
            segment = 1;
        }
        var url = $location.path().split('/');
        url = url[segment];

        if ('/' + url == path && $location.url() != '/collections?category=9,21') {
            return true;
        }
        if ($location.url() == '/collections?category=9,21' && path == '/engagement') {
            return true;
        }
        return false;
    };

    $scope.isLanguage = function (language) {
        return ($cookies.get("siteLanguage") == language) ? true : false;
    };

}
