angular.module('mapleLeafApp')
    .directive('a', [a]);

/** Directive that prevents the redirect event if the element has "#" **/
function a() {

    function link(scope, elem, attrs) {
        if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
            elem.on('click', function (e) {
                e.preventDefault();
            });
        }
    }

    return {
        restrict: 'E',
        link: link
    }

}