angular.module('mapleLeafApp')
    .directive('slider', ["$window", "$rootScope", slider]);

/** Directive for the height of the home slides **/
function slider($window, $rootScope) {

    function link(scope, elem, attrs) {
        function resize() {

            var height = $('header').height();
            var winHeight = ($window.innerHeight - height) * 0.8;

            if($window.innerWidth > 767) {
                elem.css({
                    'height': winHeight + 'px',
                    'min-height': winHeight + 'px'                    
                });
            } else {
                elem.css({
                    'height': winHeight + 'px',
                    'min-height': winHeight + 'px'
                });
            }

            /* var bulletsTop = winHeight;
            // bulletsTop = bulletsTop * 0.8;
            // bulletsTop = bulletsTop - 40;

            // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            //     if ($window.innerHeight > $window.innerWidth) {
            //         bulletsTop = bulletsTop + 50;
            //     }
            // }

            // $('#home slick .slick-dots').css({
            //     'top': bulletsTop + 'px'
            // }); */

        }

        setTimeout(function () {
            resize();
        }, 100);

        angular.element($window).bind('resize', function () {
            resize();
        })
    }

    return {
        restrict: 'A',
        link: link
    }

}