angular.module('mapleLeafApp')
    .factory('Contact', ["$http", "coronaURL", Contact]);

/** Contact Service **/
function Contact($http, coronaURL) {

    return {
        sendForm: function (params) {

            return $http({
                "url": coronaURL + "/ContactUs/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });

        }
    }

}