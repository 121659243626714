angular.module('mapleLeafApp')
    .controller('locatorController', ["$scope", "$rootScope", "$localStorage", "Dealers", "$window", "$cookies", "coronaURL", "$location", "$mdDialog", "$timeout", locatorController]);

function locatorController($scope, $rootScope, $localStorage, Dealers, $window, $cookies, coronaURL, $location, $mdDialog, $timeout) {

    $scope.showLoader();
    $scope.openManualModal = false;

    $scope.$on('$viewContentLoaded', function () {
        var coords = {
            lat: 57.8230229,
            lng: -102.6326396
        };
        setTimeout(function () {
            $rootScope.initMap(coords);
        }, 1000);
        $('header').addClass('dark-rgba');
        $('.stripes').addClass('dark-rgba');
        $('.languages').addClass('dark-rgba');
        $('.logo-header').attr({
            'src': '/images/maple-leaf-diamonds-logo-254x71.png'
        });
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
        }
    });

    $scope.$on('$routeChangeStart', function () {
        $rootScope.resetAskOne();
        $mdDialog.cancel();
        if ($cookies.get("locatorPiece") != '') {
            $cookies.put("locatorPiece", '');
        }
    });

    /** Locator controller variables **/
    $scope.selectDealer = false;
    $scope.coronaURL = coronaURL;
    $rootScope.dealerLoader = false;
    $scope.dealers = [];
    $scope.dealerStatusText = "";
    $scope.countrySelect = "canada";
    $scope.postalCode = "";
    $rootScope.locatorPartial = 1;
    $scope.dealerLogo = '';

    $scope.showManualModal = function () {
        $scope.openManualModal = true;
    };

    $scope.hideManualModal = function () {
        $scope.openManualModal = false;
    };

    (function getTexts() {
        $scope.localorTexts = $localStorage.siteTexts.locator;
        $scope.texts = $localStorage.siteTexts.locator.instructions;

        /** Functions to get the date **/
        var d = new Date();
        var weekday = $scope.localorTexts.daysOfWeek;
        $scope.day = weekday[d.getDay()];

        if ($cookies.get("locatorPiece") != "" && $cookies.get("locatorPiece") != undefined) {
            $timeout(function () {
                $scope.showManualModal();
            }, 500)
            // $mdDialog.show({
            //     controller: selectDealerInstructionsController,
            //     templateUrl: '/partials/selectDealerInstructions.tmpl.html',
            //     clickOutsideToClose: false,
            //     escapeToClose: false
            // })
            // .then(function () {
            //     if ($window.innerWidth > 767) {
            //         angular.element("#zipCode").focus();
            //     } else {
            //         $('html, body').animate({
            //             scrollTop: $('body').height()
            //         }, 0, function () {
            //             angular.element("#zipCode").focus();
            //         });
            //     }
            // });
        }
    })();

    /** Function to select country **/
    $scope.countrySelected = function (country) {
        $scope.postalCode = "";
        $scope.countrySelect = country;
        $rootScope.centerByCountry(country);
    };

    /** Search functions for dealers **/
    $rootScope.searchZipCode = function (zipcode) {

        var pattern = new RegExp("^\s*([0-9a-zA-Z? ,\/]*)\s*$");

        if (!pattern.test(zipcode)) {
            $scope.dealerStatusText = $scope.texts.locator.regex;
            return false;
        }

        if ($scope.countrySelect != 'ireland') {

            if (zipcode == '') {
                $scope.dealerStatusText = $scope.texts.enterZIP;
                return false;
            }
            var address = $scope.countrySelect + ' ' + zipcode;

        } else {

            if (zipcode == '') {
                var address = $scope.countrySelect;
            }
            var address = $scope.countrySelect + ' ' + zipcode;

        }

        $rootScope.dealerLoader = true;
        $scope.postalCode = zipcode;
        $scope.city = zipcode;

        $rootScope.userSelection = {
            "language": $cookies.get("siteLanguage"),
            "latitude": '',
            "longitude": ''
        };

        $rootScope.dealerLoader = false;

        var address = $scope.countrySelect + ' ' + zipcode;

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': address
        }, function (results, status) {

            if (status == google.maps.GeocoderStatus.OK) {
                geocodeSuccess(results);
            } else {
                geocodeError(status);
            }
        });

        function geocodeSuccess(results) {
            $rootScope.userSelection.latitude = results[0].geometry.location.lat();
            $rootScope.userSelection.longitude = results[0].geometry.location.lng();
            $scope.dealerStatusText = '';
            $rootScope.getClosestDealersFromZipCode($rootScope.userSelection, results[0].formatted_address);
        }

        function geocodeError(status) {
            $rootScope.dealerLoader = false;
            console.log(status);

            if (status == "ZERO_RESULTS") {
                $scope.dealerStatusText = $scope.localorTexts.zeroResults;
            } else if (status == "OVER_QUERY_LIMIT" || status == "UNKNOWN_ERROR" || status == "ERROR") {
                $scope.dealerStatusText = $scope.localorTexts.overQuota;
            } else if (status == "REQUEST_DENIED") {
                $scope.dealerStatusText = $scope.localorTexts.requestDenied;
            } else if (status == "INVALID_REQUEST") {
                $scope.dealerStatusText = $scope.localorTexts.requestInvalid;
            } else {
                $scope.dealerStatusText = $scope.localorTexts.overQuota;
            }
        }

    };

    $rootScope.getClosestDealersFromZipCode = function (params, city) {

        $rootScope.resultsZipCode = city;
        $scope.dealers = [];

        Dealers.sendData(params)
            .then(function successCallback(response) {
                $scope.locatorPartial = 2;
                $scope.dealers = response.data.dealers;
                $scope.hoursTitle = response.data.hoursTitle;
                $scope.paymentsTitle = response.data.paymentsTitle;
                $scope.servicesTitle = response.data.servicesTitle;
                $rootScope.setMarker($scope.dealers);
                $scope.viewSearch = false;
            }, function errorCallback(response) {
                $scope.dealerStatusText = response.statusText;
            });

        $rootScope.dealerLoader = false;

    };

    /** Function "Back" of dealer locator **/
    $scope.back = function () {
        $scope.postalCode = "";
        if ($scope.locatorPartial == 3) {
            $scope.locatorPartial = 2;
            $scope.dealerDetail = {};
            $rootScope.backToListOfDealers();
        } else if ($scope.locatorPartial == 2) {
            $scope.locatorPartial = 1;
            $scope.dealers = {};
            $scope.locatorTexts.hoursTitle = "";
            $scope.locatorTexts.paymentsTitle = "";
            $scope.locatorTexts.servicesTitle = "";
            $scope.dealerStatusText = "";
            $rootScope.rebuildToUserCoords();
        }
    };

    /** Function to use your current location **/
    $scope.currentLocation = function () {
        $rootScope.centerByLocation();
    }

    /** Function to see dealer detail **/
    $scope.showDealerDetail = function (dealer) {

        $scope.locatorPartial = 3;
        $scope.dealerDetail = dealer;
        if (dealer.logo != undefined) {
            $scope.dealerLogo = coronaURL + dealer.logo;
        }

        var coords = {
            lat: parseFloat(dealer.latitude),
            lng: parseFloat(dealer.longitude)
        }

        $rootScope.zoomToDealer(dealer.ID);

        if ($cookies.get("locatorPiece") != "" && $cookies.get("locatorPiece") != undefined) {
            addSelectDealerButton();
        }

    };

    /** Function for Dealer Locator Function **/
    function addSelectDealerButton() {
        $scope.selectDealer = true;
        angular.element(".dealerDetail").addClass('dealerButtonPadding');
    }

    $scope.acceptDealer = function (dealer) {
        $cookies.put("selectedDealer", dealer.ID);
        var piece = $cookies.get("locatorPiece");
        $cookies.put("locatorPiece", "");
        $location.path(piece);
    }

    $scope.understand = function () {
        $scope.showManualModal();
        if ($window.innerWidth > 767) {
            angular.element("#zipCode").focus();
        } else {
            $('html, body').animate({
                scrollTop: $('body').height()
            }, 0, function () {
                angular.element("#zipCode").focus();
            });
        }
    }

    $scope.isManualModalOpen = function () {
        return $scope.openManualModal
    }

    $scope.manualModalgoBack = function () {
        var piece = $cookies.get("locatorPiece");
        $cookies.put("locatorPiece", "");
        $scope.hideManualModal();
        $location.path(piece);
    }

}
