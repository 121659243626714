angular.module('mapleLeafApp')
    .factory('Collections', ["$http", "$location", "$window", "$timeout", "coronaURL", "$q", Collections]);

/** Collections Service **/
function Collections($http, $location, $window, $timeout, coronaURL, $q) {

    return {

        getCollections: function (params) {
            return $http({
                "url": coronaURL + "/GetCollections/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });
        },

        getItems: function (params) {
            return $http({
                "url": coronaURL + "/GetItems/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });
        },

        getFilters: function (params) {
            return $http({
                "url": coronaURL + "/GetFilters/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });
        },

        openCollectionSubmenu: function () {
            var url = $location.path().split('/');
            var currenPath = url[1];
            if (currenPath == 'collections' || currenPath == 'search') {
                $('.submenu').addClass('active');
            } else {
                return false;
            }
        },

        closeCollectionSubmenu: function () {
            $('.submenu').removeClass('active');
        },

        makeSearch: function (search) {
            var item = null;
            if (search == 'engagement') {
                item = {
                    frontImage: '/images/Engagement_header.png',
                    title: 'Engagement Rings',
                    description: 'Find out more about our engagement collection',
                    viewAll: 'VIEW ALL ENGAGEMENT RINGS'
                }
            }
            return item;
        }

    }

}