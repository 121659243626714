angular.module('mapleLeafApp')
    .controller('preciousMetalsController', ["$scope", "$rootScope", "$localStorage", "$sce", "$timeout", preciousMetalsController]);

function preciousMetalsController($scope, $rootScope, $localStorage, $sce, $timeout) {

    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        $('.arrowBack').removeClass('none');
        angular.element('.phoneMenu').removeClass('open');
        if ($('header').hasClass('absolute')) {
            $('header').removeClass('absolute');
        }
    });

    $scope.$on('$routeChangeStart', function () {
        $rootScope.destroyDirective();
        $('.arrowBack').addClass('none');
    });

    (function getTexts() {

        $scope.texts = {
            "title": $localStorage.siteTexts.metals.title,
            "gold": {
                "title": $localStorage.siteTexts.metals.gold.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.metals.gold.description.paragraph1,
                    "paragraph2": $sce.trustAsHtml($localStorage.siteTexts.metals.gold.description.paragraph2),
                    "paragraph3": $localStorage.siteTexts.metals.gold.description.paragraph3
                }
            },
            "karat": {
                "title": $localStorage.siteTexts.metals.karat.title,
                "description": $sce.trustAsHtml($localStorage.siteTexts.metals.karat.description),
                "list": $localStorage.siteTexts.metals.karat.list
            },
            "colours": {
                "title": $localStorage.siteTexts.metals.colours.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.metals.colours.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.metals.colours.description.paragraph2
                }
            },
            "pure": {
                "title": $localStorage.siteTexts.metals.pure.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.metals.pure.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.metals.pure.description.paragraph2,
                    "paragraph3": $localStorage.siteTexts.metals.pure.description.paragraph3
                }
            },
            "platinum": {
                "title": $localStorage.siteTexts.metals.platinum.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.metals.platinum.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.metals.platinum.description.paragraph2,
                    "paragraph3": $localStorage.siteTexts.metals.platinum.description.paragraph3
                }
            }
        };

        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

        $timeout(function () {
            if ($("html").hasClass('fp-enabled')) {
                $rootScope.destroyDirective();
                $rootScope.loadAboutDirective();
            } else {
                $rootScope.loadAboutDirective();
            }
        });

    })();

}
