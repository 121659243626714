(function () {

    angular
        .module('mapleLeafApp', [
            'ngAnimate',
            'ngMaterial',
            'ngRoute',
            'ngStorage',
            'ngCookies',
            'ngRetina',
            'vcRecaptcha',
            'slickCarousel',
            '720kb.socialshare'
        ])
        .run(["$rootScope", "$templateCache", "$location", "$cookies", "$window", "$timeout", runConfig])
        .constant('coronaURL', 'https://services.coronacompany.com')
        .constant('recaptchaKey', '6LcWCBwUAAAAAM2xGtNGbvME8a2eJAMGvxfrcPGm')
        .config(["$httpProvider", "$interpolateProvider", headersConfig])
        .config(["$mdThemingProvider", "$compileProvider", angularThemeConfig]);

    function runConfig($rootScope, $templateCache, $location, $cookies, $window, $timeout) {
        /**
            So the function can apply properly it will have to comment Collections,
            Engagement and Abuot Us HTML in home.html partial
        **/

        /** Global variable for first version of the site **/
        $rootScope.firstVersion = false;

        /** Site Language **/
        if (angular.isUndefined($cookies.get("siteLanguage"))) {
            $cookies.put("siteLanguage", "en-ca");
        }

        /** Device **/
        var setDeviceCookie = function() {
            if ($window.innerWidth > 1024) {
                $cookies.put("device", "desktop");
            } else if ($window.innerWidth >= 600 && $window.innerWidth < 1024) {
                $cookies.put("device", "tablet");
            } else if ($window.innerWidth < 600) {
                $cookies.put("device", "phone");
            }
        }();
            
        /** Fullpage Scroll ID **/
        $cookies.put("scrollID", "");

        /** Remove all cache when init app **/
        $rootScope.$on('$viewContentLoaded', function () {
            $templateCache.removeAll();
        });

        window.onbeforeunload = function () {
            $cookies.put("urlFilters", "");
        };

        /** Conf for the position of the toastaddapts to the maximum size of the page */
        angular.element(document).ready(function () {
            $timeout(function () {
                var bodyOffsetLeft = ($window.innerWidth - angular.element('body')[0].clientWidth) / 2;
                var offsetLinkHtml = document.createElement('style');
                offsetLinkHtml.innerHTML = 'md-toast{ right:' + bodyOffsetLeft + 'px !important } ';
                    
                angular.element('head').append(offsetLinkHtml);
            });
        });

        $rootScope.$on('$routeChangeStart', function () {
            $('header nav > div a').removeClass('active');
            $('.phoneMenu a').removeClass('active');
        });

    }

    function headersConfig($httpProvider, $interpolateProvider) {
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';

        /* initialize get if not there */
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }

        /* Answer edited to include suggestions from comments
        because previous version of code introduced browser-related errors  */

        /* disable IE ajax request caching */
        $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
        /*  extra */
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
    }

    function angularThemeConfig($mdThemingProvider, $compileProvider) {

        // $compileProvider.commentDirectivesEnabled(false);
        // $compileProvider.cssClassDirectivesEnabled(false);

        /** Configuration of App palette color **/
        $mdThemingProvider.theme('default')
            .primaryPalette('red')
            .accentPalette('red');

    }

})();