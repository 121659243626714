angular.module('mapleLeafApp')
    .controller('mainController', ["$scope", "$rootScope", "$localStorage", "$location", "Dealers", "$window", "DiamondTracking", "vcRecaptchaService", "$mdToast", "Languages", "$cookies", "Collections", "$timeout", "coronaURL", "$sce", "recaptchaKey", "$interval", mainController]);

function mainController($scope, $rootScope, $localStorage, $location, Dealers, $window, DiamondTracking, vcRecaptchaService, $mdToast, Languages, $cookies, Collections, $timeout, coronaURL, $sce, recaptchaKey, $interval) {
    $scope.showLoader();

    $scope.$watch(function () {
        return $localStorage.siteTexts;
    }, function (newVal, oldVal) {
        getTexts();
    });

    $scope.goToContact = function (contact) {
        $window.open(contact, "_blank");
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
            $rootScope.loadHomeDirective();
        }
    };

    $scope.$watch(function () {
        return $cookies.get("fullPageLoaded");
    }, function (newVal, oldVal) {
        if (angular.isDefined(newVal)) {
            if (newVal == "true") {
                $rootScope.goToSection($cookies.get("scrollID"));
            }
        }
    });

    $scope.$on('$viewContentLoaded', function () {
        if ($('header').hasClass('absolute')) {
            $('header').removeClass('absolute');
        }
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
            $rootScope.loadHomeDirective();
        } else {
            $rootScope.loadHomeDirective();
        }
    });

    $rootScope.scrollTo = function (url) {

        $timeout(function () {

            var offset = 0;
            var interval;

            function doScroll(offset) {
                $('html, body').animate({
                    scrollTop: offset
                }, 0, function () {
                    if ($('html, body').hasClass('no-overflow')) {
                        $('html, body').removeClass('no-overflow');
                    }
                    $cookies.put("scrollID", "");
                });
            }

            function stopWatcher() {
                $interval.cancel(interval);
            }

            var initWatcher = function () {
                stopWatcher();

                interval = $interval(function () {
                    if ($('#' + url)[0].offsetTop != window.pageYOffset) {
                        offset = $('#' + url)[0].offsetTop;
                        doScroll(offset);
                    } else {
                        stopWatcher();
                        $scope.hideLoader();
                    }
                }, 500);
            }();

        });

    };

    $scope.coronaURL = coronaURL;
    $scope.recaptchaKey = recaptchaKey;

    $rootScope.removeStyle = function () {
        $('header').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
        $('.stripes').removeClass('light light-rgba dark-rgba dark light-transparent dark-transparent');
        $('.arrow').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
        $('.languages').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
    };

    function getTexts() {

        $scope.texts = $localStorage.siteTexts.home;
        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };
        $scope.localorTexts = $localStorage.siteTexts.locator;

        /** Functions to get the date **/
        var weekday = $scope.localorTexts.daysOfWeek;
        var d = new Date();
        $scope.day = weekday[d.getDay()];
        var y = d.getFullYear();
        $rootScope.year = y;

        $scope.trackingTexts = $localStorage.siteTexts.tracking;
        $scope.trackingTexts.inputLabelError = '';
        $scope.trackingTexts.inputLabel = $scope.trackingTexts.trackTitle;

        $scope.PrincipalSlickConfig = {
            autoplay: true,
            lazyLoad: 'ondemand',
            arrows: false,
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        if ($cookies.get("siteLanguage") == "en-ca" || $cookies.get("siteLanguage") == "en-us") {
            $scope.recaptchaLanguage = "en";
        } else {
            $scope.recaptchaLanguage = "fr-CA";
        }
    };

    /** Main controller variables **/
    $rootScope.ifBrowserSupportLocation = false;

    $rootScope.dealerLoader = false;
    $rootScope.trackLoader = false;
    $scope.response = null;
    $scope.widgetId = null;
    $scope.diamondInfo = "";
    $scope.dealerStatusText = "";
    $scope.trackingNumber = "";
    $scope.dealers = [];
    $scope.countrySelect = "canada";
    $scope.postalCode = "";
    $scope.locatorPartial = 1;
    $scope.dealerLogo = '';
    $scope.RecaptchaResponse = '';

    $scope.diamondPartial = '/partials/trackingForm.html';

    function getCollections() {
        $timeout(function () {
            var params = {
                "language": $cookies.get("siteLanguage"),
                "device": $cookies.get("device")
            };

            Collections.getCollections(params)
                .then(function (params) {
                    successResponse(params.data);
                }, function (params) {
                    console.log(params);
                });

            function successResponse(response) {
                $scope.items = response.collections;

                $scope.CollectionsSlickConfig = {
                    enabled: true,
                    speed: 500,
                    autoplay: true,
                    draggable: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initOnload: true
                };

                if (angular.isUndefined($cookies.get("scrollID")) || $cookies.get("scrollID") == "") {
                    $scope.hideLoader();
                }

                if ($window.innerWidth <= 767 && !$('html').hasClass('fp-enabled')) {
                    $timeout(function () {
                        $rootScope.$on('loading:finish', function () {
                            if ($cookies.get("scrollID") != undefined && $cookies.get("scrollID") != "") {
                                $rootScope.scrollTo($cookies.get("scrollID"));
                            }
                        });
                    });
                }
            }
        });
    };
    getCollections();

    /** Validation for the collection carousel, on mobile phones **/
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if ($window.innerWidth <= 600) {
            $scope.nItems = 2;
        } else {
            $scope.nItems = 4;
        }
    } else {
        $scope.nItems = 4;
    }

    function getCategories() {
        console.log(recaptchaKey);
        var params = {
            "language": $cookies.get("siteLanguage"),
            "recaptcha_sitekey": recaptchaKey,
            "recaptcha_response_field": "000",
            "recaptcha_skip": "skiprecaptcha"
        };
        console.log(params);

        var filters = {};

        Collections.getFilters(params)
            .then(function (data) {
                filters = data.data;
                var categories = filters.categories;
                var url = '/collections?category=';
                for (var index = 0; index < categories.length; index++) {
                    if (categories[index].name == 'Engagement') {
                        if (index <= 0) {
                            url = url + categories[index].id;
                        } else {
                            url = url + ',' + categories[index].id;
                        }
                    }
                }
                $scope.engagementURL = url;
            });
    };

    /**Validations for the repcatcha**/
    $scope.setResponse = function (response, trackingNumber) {
        $scope.response = response;
        $scope.sendTrackingNumber(null, trackingNumber, response);
    };

    $scope.setWidgetId = function (widgetId) {
        $scope.widgetId = widgetId;
    };

    $scope.cbExpiration = function () {
        vcRecaptchaService.reload($scope.widgetId);
        $scope.response = null;
    };

    /**Function to search for a diamond**/
    $scope.sendTrackingNumber = function (ev, trackingNumber, reCaptchaResponse) {

        if (trackingNumber.length <= 0 || trackingNumber == "") {
            $mdToast.show(
                $mdToast.simple()
                    .textContent($scope.trackingTexts.enterNumber)
                    .position('bottom right')
                    .toastClass('md-warn')
            );
            return false;
        }

        var captchaResponse = vcRecaptchaService.getResponse($scope.widgetId);

        if ($window.innerWidth < 768) {
            var greyScreen = document.createElement('div');
            greyScreen.id = "greyScreen";
            angular.element('#tracking form').prepend(greyScreen);
        }

        if (captchaResponse != '') {

            vcRecaptchaService.reload($scope.widgetId);

            $rootScope.trackLoader = true;

            console.log(recaptchaKey);
            var params = {
                "language": $cookies.get("siteLanguage"),
                "id": trackingNumber,
                "recaptcha_sitekey": recaptchaKey,
                "recaptcha_response_field": $scope.response
            };
            console.log(params);

            DiamondTracking.sendNumber(params)
                .then(function successCallback(response) {
                    $scope.trackingTexts.inputLabelError = "";
                    $scope.trackingTexts.inputLabel = $scope.trackingTexts.anotherTitle;
                    $scope.diamondInfo = response.data;
                    $rootScope.trackLoader = false;
                    $scope.diamondPartial = '/partials/trackingResult.html';

                    vcRecaptchaService.reload($scope.widgetId);

                    if ($window.innerWidth < 768) {
                        angular.element("#greyScreen").remove();
                        $timeout(function () {
                            $('html, body').animate({
                                scrollTop: angular.element('#tracking')[0].offsetTop
                            }, 0);
                        });
                    }
                }, function errorCallback(response) {
                    angular.element('#greyScreen').remove();
                    $scope.trackingTexts.inputLabelError = response.statusText;
                    $scope.trackingTexts.inputLabel = $scope.trackingTexts.anotherTitle;
                    $rootScope.trackLoader = false;
                    vcRecaptchaService.reload($scope.widgetId);
                });

        } else {

            if ($window.innerWidth < 768) {
                angular.element('#greyScreen').remove();
                $timeout(function () {
                    $('html, body').animate({
                        scrollTop: angular.element('#tracking')[0].offsetTop
                    }, 0);
                });
            }
            $mdToast.show(
                $mdToast.simple()
                    .textContent($scope.trackingTexts.recaptchaText)
                    .position('bottom right')
                    .toastClass('md-warn')
            );
        }
    };

    /** Function to select country **/
    $scope.countrySelected = function (country) {
        $scope.postalCode = "";
        $scope.countrySelect = country;
        $rootScope.centerByCountry(country);
    };

    /** Search functions for dealers **/
    $rootScope.searchZipCode = function (zipcode) {

        var pattern = new RegExp("^\s*([0-9a-zA-Z? ,\/]*)\s*$");

        if (!pattern.test(zipcode)) {
            $scope.dealerStatusText = $scope.localorTexts.regex;
            return false;
        }

        if ($scope.countrySelect != 'ireland') {
            if (zipcode == '') {
                $scope.dealerStatusText = $scope.localorTexts.enterZIP;
                return false;
            }
            var address = $scope.countrySelect + ' ' + zipcode;

        } else {

            if (zipcode == '') {
                var address = $scope.countrySelect;
            }
            var address = $scope.countrySelect + ' ' + zipcode;

        }

        $rootScope.dealerLoader = true;
        $scope.postalCode = zipcode;
        $scope.city = zipcode;

        $rootScope.userSelection = {
            "language": $cookies.get("siteLanguage"),
            "latitude": '',
            "longitude": ''
        };

        $rootScope.dealerLoader = false;

        var address = $scope.countrySelect + ' ' + zipcode;

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': address
        }, function (results, status) {

            if (status == google.maps.GeocoderStatus.OK) {
                geocodeSuccess(results);
            } else {
                geocodeError(status);
            }
        });

        function geocodeSuccess(results) {
            $rootScope.userSelection.latitude = results[0].geometry.location.lat();
            $rootScope.userSelection.longitude = results[0].geometry.location.lng();
            $scope.dealerStatusText = '';
            $rootScope.getClosestDealersFromZipCode($rootScope.userSelection, results[0].formatted_address);
        }

        function geocodeError(status) {
            $rootScope.dealerLoader = false;

            if (status == "ZERO_RESULTS") {
                $scope.dealerStatusText = $scope.localorTexts.zeroResults;
            } else if (status == "OVER_QUERY_LIMIT" || status == "UNKNOWN_ERROR" || status == "ERROR") {
                $scope.dealerStatusText = $scope.localorTexts.overQuota;
            } else if (status == "REQUEST_DENIED") {
                $scope.dealerStatusText = $scope.localorTexts.requestDenied;
            } else if (status == "INVALID_REQUEST") {
                $scope.dealerStatusText = $scope.localorTexts.requestInvalid;
            } else {
                $scope.dealerStatusText = $scope.localorTexts.overQuota;
            }
        }

    };

    $rootScope.getClosestDealersFromZipCode = function (params, city) {

        $rootScope.resultsZipCode = city;
        $scope.dealers = [];

        Dealers.sendData(params)
            .then(function successCallback(response) {
                $scope.locatorPartial = 2;
                $scope.dealers = response.data.dealers;
                $scope.hoursTitle = response.data.hoursTitle;
                $scope.paymentsTitle = response.data.paymentsTitle;
                $scope.servicesTitle = response.data.servicesTitle;

                $rootScope.setMarker($scope.dealers);
                $scope.viewSearch = false;
            }, function errorCallback(response) {
                $scope.dealerStatusText = response.statusText;
            });

        $rootScope.dealerLoader = false;

    };

    $rootScope.cleanError = function () {
        if ($scope.dealerStatusText != '') {
            $scope.dealerStatusText = "";
            $scope.postalCode = "";
            $scope.city = "";
            $rootScope.rebuildToUserCoords();
        }
    };

    /** Function "Back" of dealer locator **/
    $scope.back = function () {
        $scope.postalCode = "";
        if ($scope.locatorPartial == 3) {
            $scope.locatorPartial = 2;
            $scope.dealerDetail = {};
            $rootScope.backToListOfDealers();
        } else if ($scope.locatorPartial == 2) {
            $scope.locatorPartial = 1;
            $scope.dealers = {};

            if ($scope.locatorTexts != undefined) {
                $scope.locatorTexts.hoursTitle = "";
                $scope.locatorTexts.paymentsTitle = "";
                $scope.locatorTexts.servicesTitle = "";
            }

            $scope.dealerStatusText = "";
            $rootScope.rebuildToUserCoords();
        }
    };

    /** Function to use your current location **/
    $scope.currentLocation = function () {
        $rootScope.centerByLocation();
    };

    /**Function to see dealer detail**/
    $scope.showDealerDetail = function (dealer) {

        $scope.locatorPartial = 3;
        $scope.dealerDetail = dealer;
        if (dealer.logo != undefined) {
            $scope.dealerLogo = coronaURL + dealer.logo;
        }

        var coords = {
            lat: dealer.latitude,
            lng: dealer.longitude
        }

        $rootScope.zoomToDealer(dealer.ID);
    };

}
