angular.module('mapleLeafApp')
    .directive('checkboxc', ["$window", "$location", checkbox]);

function checkbox($window, $location) {

    function link(scope, elem, attrs) {
        var url = $location.path().split('/');
        url = url[1];
    }

    return {
        restrict: 'E',
        scope: {
            data: '@'
        },
        link: link
    }

}