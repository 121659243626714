angular.module('mapleLeafApp')
    .factory('Dealers', ["$http", "$rootScope", "coronaURL", Dealers]);

/** Dealer Locator Service **/
function Dealers($http, $rootScope, coronaURL) {

    return {

        sendData: function (params) {

            return $http({
                "url": coronaURL + "/GetDealers/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });

        },

        getMoreInformation: function() {

            return $http({
                "url": coronaURL + "/GetMoreInformation/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });

        }

    }

}