angular.module('mapleLeafApp')
    .factory('DiamondTracking', ["$http", "coronaURL", DiamondTracking]);

/** Diamond Tracking Service **/
function DiamondTracking($http, coronaURL) {

    return {

        sendNumber: function (params) {

            return $http({
                "url": coronaURL + "/GetDiamondDetails/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });

        }

    }

}