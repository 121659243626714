angular.module('mapleLeafApp')
    .controller('collectionController', ["$scope", "$rootScope", "$location", "$window", "$cookies", "$localStorage", "Collections", "coronaURL", "hdImage", "$sce", "$timeout", collectionController]);

function collectionController($scope, $rootScope, $location, $window, $cookies, $localStorage, Collections, coronaURL, hdImage, $sce, $timeout) {

    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        $('header').addClass('absolute dark');
        $('.logo-header').attr({
            'src': '/images/maple-leaf-diamonds-logo-254x71.png'
        });
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
        }
    });

    $scope.$on('$routeChangeStart', function () {
        $('header').removeClass('absolute dark');
    });

    $scope.coronaURL = coronaURL;
    $scope.noResultsText = '';
    $scope.loader = false;
    $scope.items = [];

    $scope.isEngagement = function () {

        $scope.headerImg = '';
        var isENgagement = false;
        if ($location.search().collections == undefined && $location.search().style == undefined && $location.search().category == "9,21" && $location.search().shape == undefined) {
            isENgagement = true;
            $scope.headerImg_tablet = '/images/engagement_header_tablet.jpg';
            $scope.headerImg_mob = '/images/engagement_header_mob.jpg';
            $scope.headerImg = '/images/engagement_header.jpg';
        } else {
            $scope.headerImg_tablet = '/images/collections_header_tablet.jpg';
            $scope.headerImg_mob = '/images/collections_header_mob.jpg';
            $scope.headerImg = '/images/collections_header.jpg';
        }
        return isENgagement;
    };
    $scope.isEngagement();

    angular.element(window).bind('resize', function () {
        $scope.isEngagement();
    });

    (function getTexts(language) {
        $scope.texts = $localStorage.siteTexts.collections;
        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

        if ($scope.isEngagement()) {
            $scope.engagementData = {
                title: $scope.texts.engagementTitle,
                subtitle: $scope.texts.engagementSubtitle
            };
        } else {
            $scope.engagementData = {
                title: $scope.texts.title,
                subtitle: $scope.texts.description
            };
        }

    })();

    function getCollections() {
        var params = {
            "language": $cookies.get("siteLanguage"),
            "device": $cookies.get("device")
        };

        Collections.getCollections(params)
            .then(function (params) {
                $scope.collections = params.data.collections;
            }, function (params) {
                console.log(params);
            });

    };

    var arrayID = 0;

    $scope.getItems = function (start) {

        $scope.callingItems = true;
        var colFilter = [];
        var catFilter = [];
        var shapeFilter = [];
        var styleFilter = [];
        var filtersCount = 0;

        if ($location.search().collections != undefined) {
            var collection = $location.search().collections;
            collection = collection.split('-');
            for (var index = 0; index < collection.length; index++) {
                var element = collection[index].split(',');
                for (var indexx = 0; indexx < element.length; indexx++) {
                    colFilter.push(Number(element[indexx]));
                }
            }
        }

        if ($location.search().category != undefined) {
            var category = $location.search().category;
            category = category.split('-');
            for (var index = 0; index < category.length; index++) {
                var element = category[index].split(',');
                for (var indexx = 0; indexx < element.length; indexx++) {
                    catFilter.push(Number(element[indexx]));
                }
            }
        }

        if ($location.search().shape != undefined) {
            var shape = $location.search().shape;
            shape = shape.split('-');
            for (var index = 0; index < shape.length; index++) {
                var element = shape[index].split(',');
                for (var indexx = 0; indexx < element.length; indexx++) {
                    shapeFilter.push(Number(element[indexx]));
                }
            }
        }

        if ($location.search().style != undefined) {
            var style = $location.search().style;
            style = style.split('-');
            for (var index = 0; index < style.length; index++) {
                var element = style[index].split(',');
                for (var indexx = 0; indexx < element.length; indexx++) {
                    styleFilter.push(Number(element[indexx]));
                }
            }
        }

        var urlFilters = [];
        $scope.urlFilters = urlFilters = [{
            "collections": colFilter,
            "styles": styleFilter,
            "shapes": shapeFilter,
            "categories": catFilter
        }];

        var params = {
            "language": $cookies.get("siteLanguage"),
            "filters": urlFilters,
            "search": "",
            "record_start": ($scope.items.length <= 0) ? 1 : $scope.items.length + 1,
            "record_count": 21,
            "device": $cookies.get("device")
        };

//        console.log(params);
        if (params.record_start != null) {
        	fbq('track', 'ViewContent', { "EngagementFilters" : urlFilters, "ItemStart" : params.record_start } );
            Collections.getItems(params)
                .then(function (response) {
                    if (response.data.items.length > 0) {
                        for (var index = 0; index < response.data.items.length; index++) {
                            var temp = response.data.items[index];
                            temp.arrayID = arrayID;
                            $scope.items.push(temp);
                            arrayID++;
                        }
                    } else {
                        $scope.stopCalling = true;
                        if ($scope.items.length <= 0) {
                            $scope.noResultsText = 'No Results';
                        }
                    }

                    if (response.data.items.length < 21) {
                        $scope.stopCalling = true;
                    }

                    $scope.callingItems = false;
                    $scope.loader = true;
                }, function (response) {
                    console.log(response);
                    $scope.noResultsText = 'No Results';
                    $scope.statusText = response.statusText;
                    $scope.callingItems = false;
                    $scope.stopCalling = true;
                    $scope.loader = true;
                });
        }

    };

    if ($location.search().collections == undefined && $location.search().style == undefined && $location.search().category == undefined && $location.search().shape == undefined) {
        $scope.partial = 'partials/collections_partial.html';
        $scope.collections = [];
        getCollections();
    } else {
        $scope.partial = 'partials/pieces_partial.html';
        $scope.items = [];
        $scope.itemsStart = 1;
        $scope.callingItems = false;
        $scope.stopCalling = false;
        $scope.getItems($scope.itemsStart);

        function askBottom() {
            if ($scope.stopCalling) return;

            if ($scope.callingItems) return;

            if (angular.element('infiniteScrollBottom').length > 0) {
                if (($window.pageYOffset + $window.innerHeight + 350) > angular.element('infiniteScrollBottom')[0].offsetTop) {
                    $scope.itemsStart += 21;
                    $scope.loader = false;
                    $scope.getItems($scope.itemsStart);
                }
            }
        };

        angular.element($window).bind('scroll mousewheel', function () {
            askBottom();
        });

        document.addEventListener('touchmove', function () {
            askBottom();
        });
    }

    $scope.goToDetail = function (piece) {
        $localStorage.itemsList = $scope.items;
        $localStorage.filters = $scope.urlFilters;
        $localStorage.urlFilters = $location.absUrl();
        var url = "/collections/" + piece.collectionId + "/" + piece.id;
        $timeout(function () {
            $location.path(url);
        });
    };

}
