angular.module('mapleLeafApp')
    .directive('initFullpage', ["$window", "$location", "$rootScope", "$routeParams", "$cookies", "$timeout", "hdImage", initFullpage]);

/** Directive For Init Full page **/
function initFullpage($window, $location, $rootScope, $routeParams, $cookies, $timeout, hdImage) {

    function link(scope, element, attrs) {

        var actualIndex = 1,
            actualTheme = '',
            destroyed = true,
            askDealerSection = false;

        $cookies.put("fullPageLoaded", false);

        scope.$on('$routeChangeStart', function (e, next, current) {
            $('.arrow.down').off('resize', function() {
                angular.noop();
            });
            $($window).off('resize', function () {
                angular.noop();
            });
        });

        /** Function to create the "Init full Page" of home **/
        $rootScope.loadHomeDirective = function () {
            if ($window.innerWidth > 767 && $cookies.get("fullPageLoaded") == "false" && angular.isDefined($cookies.get("fullPageLoaded"))) {

                $(element).fullpage({
                    scrollOverflow: false,
                    navigation: false,
                    fitToSection: true,
                    responsiveWidth: 767,
                    afterLoad: function (anchorLink, index) {
                        $cookies.put("fullPageLoaded", true);
                    },
                    onLeave: function (index, nextIndex, direction) {
                        actualIndex = nextIndex;
                        changeTheme(nextIndex);
                        ifDealerSection(nextIndex);
                        ifLeaveDealerSection(index);
                        checkMenu(nextIndex);
                    },
                    afterResponsive: function (isResponsive) {
                        $cookies.put("fullPageLoaded", false);                        
                        destroyed = true;
                        $.fn.fullpage.destroy('all');
                        $.fn.fullpage.setAutoScrolling(false);
                        $.fn.fullpage.setFitToSection(false);
                    }
                });

                destroyed = false;
                insertNavigation();
            }

            changeTheme(1);

            if($window.innerWidth <= 767 && !$('html').hasClass('fp-enabled')) {
                $timeout(function () {
                    $rootScope.$on('loading:finish', function (){   
                        if($cookies.get("scrollID") != undefined && $cookies.get("scrollID") != "") {
                            $rootScope.scrollTo($cookies.get("scrollID"));
                        }
                    });
                });            
            } 
        };

        /** Function to create the "Init full Page" of about **/
        $rootScope.loadAboutDirective = function () {
            if ($window.innerWidth > 767 && $cookies.get("fullPageLoaded") == "false" && angular.isDefined($cookies.get("fullPageLoaded"))) {
                $timeout(function () {
                    $(element).fullpage({
                        scrollOverflow: true,
                        navigation: false,
                        fitToSection: false,
                        responsiveWidth: 767,
                        afterLoad: function (anchorLink, index) {
                            $cookies.put("fullPageLoaded", true);
                        },
                        onLeave: function (index, nextIndex, direction) {
                            actualIndex = nextIndex;
                            changeTheme(nextIndex);
                        },
                        afterResponsive: function (isResponsive) {
                            $cookies.put("fullPageLoaded", false);
                            destroyed = true;
                            $.fn.fullpage.destroy('all');
                            $.fn.fullpage.setAutoScrolling(false);
                            $.fn.fullpage.setFitToSection(false);
                        }
                    });
                });

                destroyed = false;
                insertNavigation();
            }
            
            changeTheme(1);

            $timeout(function () {
                $rootScope.$on('loading:finish', function (){   
                    if($window.innerWidth <= 767 && $cookies.get("scrollID") != undefined && $cookies.get("scrollID") != "") {
                        $rootScope.scrollTo($cookies.get("scrollID"));
                    }
                });
            });  
        };

        /** Function to destroy the "Init Full Page" **/
        $rootScope.destroyDirective = function () {
            if ($('html').hasClass('fp-enabled')) {
                $cookies.put("fullPageLoaded", false);                
                $.fn.fullpage.destroy('all');
                $.fn.fullpage.setAutoScrolling(false);
                $.fn.fullpage.setFitToSection(false);
            }
        };

        /** Function to rebuild the "Init Full Page" **/
        $rootScope.rebuildDirective = function () {
            $.fn.fullpage.reBuild();
        };

        /** Function to change section **/
        $rootScope.goToSection = function (url) {
            if (url != undefined && url != "") {
                $.each($(element)[0].children, function (i, el) {

                    var elementId = $(el)[0].attributes['id'].value;

                    if (elementId == url) {
                        var elId = i + 1;
                        checkMenu(elId);
                        $.fn.fullpage.setScrollingSpeed(0);
                        $.fn.fullpage.moveTo(elId);
                        $.fn.fullpage.setScrollingSpeed(700);                        
                    }

                    if ($window.innerWidth < 960) {
                        angular.element('.phoneMenu').removeClass('open');
                    }
                });
                /* $cookies.put("scrollID", "");
                // scrolling = false; */
            }
        };

        /* $rootScope.scrollTo = function(url) {
        //     $timeout(function () {
        //         var offset = $('#' + url)[0].offsetTop;
                
        //         $('html, body').animate({
        //             scrollTop: offset
        //         }, 0, function () {
        //             if ($('html, body').hasClass('no-overflow')) {
        //                 $('html, body').removeClass('no-overflow');
        //             }
        //             $cookies.put("scrollID", "");
        //         });
        //     });
        // };  */

        /** Function to turn menu options to red **/
        function checkMenu(index) {
            var section = '',
                url = '';

            $.each($(element)[0].children, function (i, el) {
                if (i + 1 == index) {
                    section = $(el)[0].attributes.id.value;
                }
            });

            if($window.innerWidth > 959) {
                $.each($('header nav > div'), function (i, el) {
                    url = $(el).find('a')[0].attributes.dhref.value;

                    var regex = new RegExp(section, 'g');
                    ($(el).find('a').hasClass('active')) ? $(el).find('a').removeClass('active') : angular.noop();
                    
                    if (url.match(regex) != null && url.match(regex).length > 0) {
                        $(el).find('a').addClass('active');
                    }
                });
            } else {
                $.each($('.phoneMenu a'), function (i, el) {
                    if(angular.isDefined($(el)[0].attributes.dhref)) {

                        url = $(el)[0].attributes.dhref.value;
                        
                        var regex = new RegExp(section, 'g');
                        
                        ($(el).hasClass('active')) ? $(el).removeClass('active') : angular.noop();
                        
                        if (url.match(regex) != null && url.match(regex).length > 0) {
                            $(el).addClass('active');
                        }
                    }
                });
            }

        };

        /** Function to determine if you are in the "Dealer Locator" section **/
        function ifDealerSection(index) {

            if (askDealerSection) {
                return false
            }

            var scrolledEl = $(element)[0].children[index - 1];
            var locatorIndex = 0;
            var coords = {
                lat: 57.8230229,
                lng: -102.6326396
            };

            $.each($(element)[0].children, function (i, e) {
                if ($(e).attr('id') == "locator") {
                    locatorIndex = i + 1;
                }
            });

            if (index > locatorIndex) {
                $rootScope.initMap(coords);

                askDealerSection = true;
            }


            if ($(scrolledEl)[0].attributes.id.value == 'locator') {
                $rootScope.initMap(coords);

                askDealerSection = true;
            }

        };

        function ifLeaveDealerSection(index) {
            var scrolledEl = $(element)[0].children[index - 1];
            if($(scrolledEl)[0].attributes.id.value == 'locator') {
                $rootScope.cleanError();
            }
        };

        /** Function to enable "Init Full Page" when resizing screen **/
        function resize() {
            var segment = $location.path().split('/');
            if ($window.innerWidth > 768) {
                if (destroyed) {
                    checkMenu(1);
                    if (segment[1] == '') {
                        $rootScope.loadHomeDirective();
                    } else if (segment[1] == 'about') {
                        $rootScope.loadAboutDirective();
                    }
                }
            } else {
                $('.arrow.down').remove();
            }
        };

        /** Directive to change the header background in desktop **/
        var changeTheme = function (index) {
            $.each($(element)[0].children, function (i, el) {
                if (i == index - 1) {

                    $('#fp-nav').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                    $('.arrow').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                    $('header').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                    $('.languages').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                    $('.arrowBack').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                    $('.menuBtn .stripes').removeClass('light light-rgba dark-rgba dark background-light light-transparent dark-transparent');
                    actualTheme = "";
                    actualTheme = $(el)[0].attributes['color'].value;
                    $('#fp-nav').addClass(actualTheme);
                    $('.arrow').addClass(actualTheme);
                    $('header').addClass(actualTheme);
                    $('.languages').addClass(actualTheme);
                    $('.arrowBack').addClass(actualTheme);
                    $('.menuBtn .stripes').addClass(actualTheme);

                    if (actualTheme == 'light' || actualTheme == 'light-rgba' || actualTheme == 'light-transparent') {
                        $('.logo-header').attr({
                            'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-whiteletters-244x69.png')
                        });
                    } else {
                        $('.logo-header').attr({
                            'src': hdImage.getImgUrl('/images/maple-leaf-diamonds-logo-254x71.png')
                        });
                    }

                }
            });
        };

        /** Function to verify if it is desktop **/
        var isDesktop = function () {
            if ($window.innerWidth > 1024) {
                return true
            }
            return false
        };

        var insertNavigation = function () {

            var arrowDown = '<div class="arrow down">' +
                '<div class="cont">' +
                '<span></span>' +
                '</div>' +
                '</div>';

            $('body').append(arrowDown);
            $('.arrow.down').bind('click', function (ev) {
                downSection(ev);
            });
        };

        /** Function to go to the lower section **/
        var downSection = function () {
            $.fn.fullpage.moveSectionDown();
        };

        /** Functions to verify mouse actions **/
        var checkMousePosition = function (ev) {
            if ($location.path() == '/' || $location.path() == '/about/precious-metals' || $location.path() == '/about/canadian-diamonds' || $location.path() == '/about/5c' || $location.path() == '/about/certification') {
                /* if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && isDesktop()) {  */
                if (actualIndex < $(element)[0].children.length) {
                    if (ev >= $($window).height() - 110) {
                        $('.arrow.down').addClass("show");
                    } else {
                        $('.arrow').removeClass("show");
                    }
                } else {
                    $('.arrow').removeClass("show");
                }
                /* }  */
            }
        };

        $($window).bind('resize', function () {
            resize();
        });

        $($window).on('mousemove', function (ev) {
            checkMousePosition(ev.clientY);
        });

        $(document).on('mouseenter', '.cont ', function (ev) {
            if ($('#mapControls > div').height() > $('#mapControls').height()) {
                $.fn.fullpage.setMouseWheelScrolling(false);
                $.fn.fullpage.setAllowScrolling(false);
            }
        });

        $(document).on('mouseleave', '.cont ', function (ev) {
            if ($('#mapControls > div').height() > $('#mapControls').height()) {
                $.fn.fullpage.setMouseWheelScrolling(true);
                $.fn.fullpage.setAllowScrolling(true);
            }
        });

    }

    return {
        restrict: 'A',
        link: link
    }

}