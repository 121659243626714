angular.module('mapleLeafApp')
    .directive('mapleLoader', [mapleLoader]);

/** Directive for show or hide the main loader **/
function mapleLoader() {

    function link(scope, elem, attrs) {
       
    }

    return {
        restrict: 'A',
        scope:{
            showLoader: '&',
            hideLoader: '&'
        },
        link: link
    }

}