angular.module('mapleLeafApp')
    .controller('certificationController', ["$scope", "$rootScope", "$localStorage", "$window", "$location", "$cookies", "$sce", "$timeout", certificationController]);

function certificationController($scope, $rootScope, $localStorage, $window, $location, $cookies, $sce, $timeout) {

    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        $('.arrowBack').removeClass('none');
        angular.element('.phoneMenu').removeClass('open');
        if ($('header').hasClass('absolute')) {
            $('header').removeClass('absolute');
        }
    });

    $scope.$on('$routeChangeStart', function () {
        $rootScope.destroyDirective();
        $('.arrowBack').addClass('none');
    });

    (function getTexts() {

        $scope.texts = {
            "title": $localStorage.siteTexts.certification.title,
            "certificate": {
                "title": $localStorage.siteTexts.certification.certificate.title,
                "description": $localStorage.siteTexts.certification.certificate.description
            },
            "tracking": {
                "title": $localStorage.siteTexts.certification.tracking.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.certification.tracking.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.certification.tracking.description.paragraph2
                },
                "url": $localStorage.siteTexts.certification.tracking.url
            },
            "responsible": {
                "title": $localStorage.siteTexts.certification.responsible.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.certification.responsible.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.certification.responsible.description.paragraph2,
                    "paragraph3": $localStorage.siteTexts.certification.responsible.description.paragraph3
                }
            },
            "canadamark": {
                "title": $localStorage.siteTexts.certification.canadamark.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.certification.canadamark.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.certification.canadamark.description.paragraph2,
                    "paragraph3": $sce.trustAsHtml($localStorage.siteTexts.certification.canadamark.description.paragraph3)
                }
            }
        };

        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };
        $timeout(function () {
            if ($("html").hasClass('fp-enabled')) {
                $rootScope.destroyDirective();
                $rootScope.loadAboutDirective();
            } else {
                $rootScope.loadAboutDirective();
            }
        });

    })();

    $scope.goToTrack = function ($ev) {
        $ev.preventDefault();
        $location.path('/');
        setTimeout(function () {
            if ($window.innerWidth < 992) {
                $rootScope.scrollTo($ev, 'tracking');
            } else {
                $rootScope.goToSection('tracking');
            }
        }, 500);
    };

}
