angular.module('mapleLeafApp')
    .directive('engagementSize', ["$window", engagementSize]);

/** Directive for set Height of Engagement Section **/
function engagementSize($window) {

    function link(scope, element, attrs) {
        function resize() {
            if($window.innerWidth >= 768) {
                var size = $window.innerHeight - $('header').height();
                element.css({
                    height: size + 'px'
                });
            }
        }

        resize();

        angular.element($window).bind('resize', function () {
            resize();
        });
    }

    return {
        restrict: 'A',
        link: link
    }

}