angular.module('mapleLeafApp')
    .directive('dealersMap', ["$window", "Dealers", "$rootScope", "$timeout", "$mdDialog", "$cookies", dealersMap]);

/** Directive For Init Dealers Locator Map **/
function dealersMap($window, Dealers, $rootScope, $timeout, $mdDialog, $cookies) {

    /** Directive link function **/
    function link(scope, element, attrs) {

        var map, dealersCenterCoords, dealersZoom, lastPosition, lastZoom = 4,
            userPosition, infoWindow, markers = [],
            markersposition = [],
            lastCountry = 'Canada';

        $rootScope.firstAlert = 0;

        /* Opera 8.0+ */
        var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

        /* Firefox 1.0+ */
        var isFirefox = typeof InstallTrigger !== 'undefined';

        /* Safari 3.0+ "[object HTMLElementConstructor]"  */
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        /* Internet Explorer 6-11 */
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;

        /* Edge 20+ */
        var isEdge = !isIE && !!window.StyleMedia;

        /* Chrome 1+ */
        var isChrome = !!window.chrome && !!window.chrome.webstore;

        /** map config **/
        var mapOptions = {
            center: new google.maps.LatLng(56.130366, -106.34677099999999),
            zoom: lastZoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            gestureHandling: 'cooperative',
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            }
        };

        $rootScope.searchONclick = function () {
            /* if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            //     map.addListener('dblclick', function(e) {
            //         checkposition(e.latLng, map);
            //     }); 
            // } else { */
            map.addListener('click', function (e) {
                checkposition(e.latLng, map);
            });
            /* } */
            map.addListener('zoom_changed', function (e) {
                lastZoom = map.getZoom();
            });

        };

        /** init the map **/
        $rootScope.initMap = function (coords) {
            /* if (map === void 0) {  */
            lastPosition = new google.maps.LatLng(coords.lat, coords.lng);
            mapOptions.center = new google.maps.LatLng(coords.lat, coords.lng);
            map = new google.maps.Map(element[0], mapOptions);
            /* }  */
            $rootScope.searchONclick();
            $rootScope.userLocation(false);

            adjust();
        };

        $rootScope.rebuildToUserCoords = function () {
            mapOptions.center = lastPosition;
            mapOptions.zoom = lastZoom;
            map = new google.maps.Map(element[0], mapOptions);
            $rootScope.searchONclick();
            adjust();
        };

        /** Function to determine the map zoom **/
        function getBoundsZoomLevel(bounds, mapDim) {
            var WORLD_DIM = {
                height: 256,
                width: 256
            };
            var ZOOM_MAX = 21;

            function latRad(lat) {
                var sin = Math.sin(lat * Math.PI / 180);
                var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
                return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
            }

            function zoom(mapPx, worldPx, fraction) {
                return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
            }

            var ne = bounds.getNorthEast();
            var sw = bounds.getSouthWest();

            var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

            var lngDiff = ne.lng() - sw.lng();
            var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

            var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
            var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

            return Math.min(latZoom, lngZoom, ZOOM_MAX);
        };

        function checkposition(latLng, map) {

            lastPosition = latLng;

            var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                name: "markerposition"
            });

            if (markersposition.length == 0) {
                markersposition.push(marker);
            } else {
                for (var i = 0; i < markersposition.length; i++) {
                    if (markersposition[i]['name'] == "markerposition") {
                        markersposition[i].setMap(null);
                        markersposition.splice(i, 1);
                    }
                }
                markersposition.push(marker);
            }

            var confirm = $mdDialog.confirm()
                .title('Do you want to find dealers near the chosen location?')
                .ariaLabel('Browser Location')
                .ok('Find Dealer')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    accept();
                }, function () {
                    cancel();
                });

            function accept() {

                $rootScope.dealerLoader = true;

                $rootScope.userSelection = {
                    "language": $cookies.get("siteLanguage"),
                    "latitude": latLng.lat(),
                    "longitude": latLng.lng()
                };

                var coords = latLng.lat() + ',' + latLng.lng();

                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'address': coords
                }, function (results, status) {

                    if (status == google.maps.GeocoderStatus.OK) {

                        $rootScope.resultsZipCode = results[0].formatted_address;

                    } else {

                        console.log("Geocode was not successful for the following reason: " + status);
                    }
                });

                $rootScope.dealerLoader = true;

                $rootScope.getClosestDealersFromZipCode($rootScope.userSelection);
            }

            function cancel() {
                return false;
            }

        };

        /** put markers **/
        $rootScope.setMarker = function (dealers) {

            var newMarkers, mapDim = {},
                mapHeight, mapWidth;

            mapHeight = document.getElementById('gmaps').clientHeight;
            mapWidth = document.getElementById('gmaps').clientWidth;

            mapDim = {
                height: mapHeight,
                width: mapWidth
            }

            /** remove old markers **/
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(null);
                markers.splice(i, 1);
            }

            markers = [];

            newMarkers = dealers;

            /** add marker to array **/
            for (i = 0; i < newMarkers.length; i++) {
                markers.push(newMarkers[i]);
            }

            /* Remove style from old selected dealer */
            function remove() {
                for (a = 0; a < markers.length; a++) {
                    angular.element('#' + markers[a].dealer).removeClass('active');
                }
            };

            for (i = 0; i < markers.length; i++) {
                var coords = {
                    lat: parseFloat(markers[i].latitude),
                    lng: parseFloat(markers[i].longitude)
                };

                markers[i] = new google.maps.Marker({
                    position: new google.maps.LatLng(coords),
                    map: map,
                    icon: window.location.origin + '/images/diamond_map.png',
                    id: i,
                    dealer: markers[i].ID
                });

                google.maps.event.addListener(markers[i], 'click', (function (marker, i) {
                    return function () {
                        remove();
                        map.setCenter(marker.position);
                        map.setZoom(15);
                        angular.element('#' + marker.dealer).addClass('active');
                    }
                })(markers[i], i));

            }

            var bounds = null;

            if (markers.length > 0) {
                var boundsTemp = new google.maps.LatLngBounds();

                $.each(markers, function () {
                    boundsTemp.extend(this.getPosition());
                });
                bounds = boundsTemp;
            }

            var fixBounds = {
                lat: 0,
                lng: 0
            };

            fixBounds.lat = bounds.getCenter().lat();
            fixBounds.lng = bounds.getCenter().lng();

            dealersCenterCoords = (bounds) ? fixBounds : new google.maps.LatLng(0, 0);
            dealersZoom = (bounds) ? getBoundsZoomLevel(bounds, mapDim) : 0;

            mapOptions.center = dealersCenterCoords;
            mapOptions.zoom = dealersZoom;
            map = new google.maps.Map(element[0], mapOptions);

            adjust();

            map.addListener('click', function (e) {
                checkposition(e.latLng, map);
            });

            $.each(markers, function () {
                this.setMap(map);
            });

        };

        /** Function to verify the current location of the user **/
        $rootScope.userLocation = function (locationBtn) {
            if (((isChrome || isOpera || isSafari) && location.protocol === 'https:') || (isFirefox || isIE || isEdge)) {

                $rootScope.ifBrowserSupportLocation = true;
                /* Try HTML5 geolocation. */

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: parseFloat(position.coords.latitude),
                                lng: parseFloat(position.coords.longitude)
                            };

                            var geocoder = new google.maps.Geocoder();

                            geocoder.geocode({
                                'location': {
                                    lat: parseFloat(position.coords.latitude),
                                    lng: parseFloat(position.coords.longitude)
                                }
                            }, function (results, status) {
                                if (status == google.maps.GeocoderStatus.OK) {
                                    geocodeSuccess(results);
                                } else {
                                    geocodeError(status);
                                }
                            });

                            $rootScope.centerMap(pos);

                            userPosition = pos;
                            lastPosition = new google.maps.LatLng(pos.latitude, pos.longitude);

                            if (locationBtn) {

                                $rootScope.dealerLoader = true;

                                $rootScope.userSelection = {
                                    "language": $cookies.get("siteLanguage"),
                                    "latitude": position.coords.latitude,
                                    "longitude": position.coords.longitude
                                };

                                $rootScope.getClosestDealersFromZipCode($rootScope.userSelection, $rootScope.resultsZipCode);

                            }

                        },
                        function (params) {
                            handleLocationError(params);
                        }
                    );

                } else {
                    /* Browser doesn't support Geolocation */
                    handleLocationError(params);
                }

            }

            function handleLocationError(error) {
                console.log(error);
                if (error.code == 1 && $rootScope.firstAlert == 1) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent(angular.element(document.querySelector('#popupContainer')))
                            .clickOutsideToClose(true)
                            .title(error.message)
                            .ariaLabel(error.message)
                            .ok('Close window')
                    );
                }
                $rootScope.firstAlert = 1;
            }

            function geocodeSuccess(results) {
                $rootScope.resultsZipCode = results[0].formatted_address;
            }

            function geocodeError(status) {
                $rootScope.dealerLoader = false;
                $scope.dealerStatusText = "Geocode was not successful for the following reason: " + status;
                return false;
            }
        };

        $rootScope.centerByLocation = function () {

            if (userPosition == undefined) {
                $rootScope.userLocation(true);
                return false;
            }

            $rootScope.dealerLoader = true;

            var userSelection = {
                "language": $cookies.get("siteLanguage"),
                "latitude": userPosition.lat,
                "longitude": userPosition.lng
            };

            var geocoder = new google.maps.Geocoder();

            geocoder.geocode({
                'location': {
                    lat: parseFloat(userPosition.lat),
                    lng: parseFloat(userPosition.lng)
                }
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    geocodeSuccess(results);
                } else {
                    geocodeError(status);
                }
            });

            function geocodeSuccess(results) {
                $rootScope.resultsZipCode = results[0].formatted_address;
            }

            function geocodeError(status) {
                $rootScope.dealerLoader = false;
                $scope.dealerStatusText = "Geocode was not successful for the following reason: " + status;
                return false;
            }

            $rootScope.getClosestDealersFromZipCode(userSelection, $rootScope.resultsZipCode);

        };

        $rootScope.checkBrowserLocatorEnable = function () {
            if (navigator.geolocation) {

                navigator.geolocation.getCurrentPosition(function (params) {
                    console.log(params);
                }, function (params) {
                    console.log(params);
                });

            } else {
                // Browser doesn't support Geolocation */
                handleLocationError(false, map.getCenter());
            }

            function handleLocationError(browserHasGeolocation, pos) {
                $rootScope.ifBrowserSupportLocation = false;
            }
        };

        /** Function to center the map **/
        $rootScope.centerMap = function (coords) {

            lastPosition = new google.maps.LatLng(coords.lat, coords.lng);
            mapOptions.center = new google.maps.LatLng(coords.lat, coords.lng);
            mapOptions.zoom = 16;
            map = new google.maps.Map(element[0], mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(coords.lat, coords.lng),
            });

            marker.setMap(map);

            $rootScope.searchONclick();

        };

        $rootScope.centerByCountry = function (country) {

            lastCountry = country;

            var geocoder = new google.maps.Geocoder();

            geocoder.geocode({
                'address': country
            }, function (results, status) {

                if (status == google.maps.GeocoderStatus.OK) {
                    geocodeSuccess(results);
                } else {
                    geocodeError(status);
                }

            });

            function geocodeSuccess(results) {
                if (country == 'usa') {
                    lastZoom = 5;
                    lastPosition = new google.maps.LatLng(39.9871241, -100.418167);
                    if ($window.innerWidth <= 1024) {
                        lastZoom = 4;
                        lastPosition = new google.maps.LatLng(38.4972538, -100.373718);
                    }
                    if ($window.innerWidth <= 767) {
                        lastZoom = 3;
                        lastPosition = new google.maps.LatLng(39.7660699, -99.2497037);
                    }
                    if ($window.innerWidth <= 375) {
                        lastZoom = 3;
                        lastPosition = new google.maps.LatLng(39.2401735, -101.8395946);
                    }
                    map.setZoom(lastZoom);
                }
                if (country == 'canada') {
                    lastZoom = 4;
                    lastPosition = new google.maps.LatLng(57.8230229, -102.6326396);
                    if ($window.innerWidth <= 1024) {
                        lastZoom = 3;
                        lastPosition = new google.maps.LatLng(56.176862, -101.5623277);
                    }
                    if ($window.innerWidth <= 992) {
                        lastZoom = 3;
                        lastPosition = new google.maps.LatLng(53.8079058, -99.4529527);
                    }
                    if ($window.innerWidth <= 767) {
                        lastZoom = 3;
                        lastPosition = new google.maps.LatLng(54.4771763, -99.5408433);
                    }
                    if ($window.innerWidth <= 767) {
                        lastZoom = 3;
                        lastPosition = new google.maps.LatLng(54.3236887, -99.5408433);
                    }
                    map.setZoom(lastZoom);
                }
                if (country == 'uk') {
                    lastPosition = results[0].geometry.location;
                    map.fitBounds(results[0].geometry.bounds);
                    lastZoom = map.getZoom() + 1;
                    map.setZoom(lastZoom);
                }
                if (country == 'ireland') {
                    lastPosition = results[0].geometry.location;
                    map.fitBounds(results[0].geometry.bounds);
                    lastZoom = map.getZoom();
                }
                if (country == 'australia') {
                    lastPosition = results[0].geometry.location;
                    map.fitBounds(results[0].geometry.bounds);
                    lastZoom = map.getZoom() + 1;
                    map.setZoom(lastZoom);
                }
                map.setCenter(lastPosition);
            }

            function geocodeError(status) {
                $rootScope.dealerLoader = false;
                $scope.dealerStatusText = "Geocode was not successful for the following reason: " + status;
                return false;
            }

        };

        $rootScope.zoomToDealer = function (dealerID) {
            for (var index = 0; index < markers.length; index++) {
                if (dealerID == markers[index].dealer) {
                    map.setCenter(markers[index].position);
                    map.setZoom(15);
                }
            }
        };

        $rootScope.backToListOfDealers = function () {
            map.setCenter(dealersCenterCoords);
            map.setZoom(dealersZoom);
        };

        function adjust() {

            setTimeout(function () {

                var winHeight = $window.innerHeight - $('header')[0].clientHeight - 2;

                var winWidth = $window.innerWidth;

                if (winWidth >= 960) {
                    $('#mapControls').css({
                        'height': winHeight + 'px',
                        "float": "left"
                        // "margin-top": $('header')[0].clientHeight + 'px' */
                    });
                    $('.contOptionsMap').css({
                        'height': winHeight + 'px'
                    });
                    element.css({
                        'height': (winHeight + 2) + 'px'
                        // "margin-top": $('header')[0].clientHeight + 'px' */
                    });
                } else if (winWidth >= 768 && winWidth < 960) {
                    $('#mapControls').css({
                        'height': (winHeight / 2) + 'px',
                        "margin-top": '0px'
                    });
                    $('.contOptionsMap').css({
                        'height': winHeight + 'px'
                    });
                    if ($window.innerHeight > winWidth) {
                        element.css({
                            'height': (winHeight / 2) + 'px'
                            // "margin-top": $('header')[0].clientHeight + 'px' */
                        });
                    } else {
                        element.css({
                            'height': '250px'
                            // "margin-top": $('header')[0].clientHeight + 'px' */
                        });
                    }
                } else {
                    $('#mapControls').css({
                        "margin-top": '0px',
                        'height': 'auto'
                    });
                    $('.contOptionsMap').css({
                        'height': 'auto'
                    });
                    if ($window.innerHeight > winWidth) {
                        element.css({
                            'height': ($window.innerHeight / 2) + 'px'
                            // "margin-top": $('header')[0].clientHeight + 'px' */
                        });
                    } else {
                        element.css({
                            'height': '250px'
                            // "margin-top": $('header')[0].clientHeight + 'px' */
                        });
                    }
                }

            });

        };

        angular.element($window).bind('resize', function () {
            adjust();
        })

        if ((isChrome || isOpera || isSafari) && location.protocol === 'https:') {
            $rootScope.ifBrowserSupportLocation = true;
        } else if (isFirefox || isIE || isEdge) {
            $rootScope.ifBrowserSupportLocation = true;
        }

    }

    return {
        restrict: 'A',
        template: '<div id="gmaps"></div>',
        replace: true,
        link: link
    }

}