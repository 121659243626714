angular.module('mapleLeafApp')
    .directive('resizeResponsive', ["$window", resizeResponsive]);

/** Directive for height of sections in responsive **/
function resizeResponsive($window) {

    function link(scope, elem, attrs) {
        function resize() {
            var winHeight = $window.innerHeight;
            if ($window.innerHeight <= 500) {
                elem.css({
                    'min-height': 'auto',
                    'height': 'auto'
                });
            } else {
                elem.css({
                    'min-height': 100 + '%',
                    'height': 100 + '%'
                });
            }
        }

        resize();

        angular.element($window).bind('resize', function () {
            resize();
        })
    }

    return {
        restrict: 'A',
        scope: {
            data: '@'
        },
        link: link
    }

}