angular.module('mapleLeafApp')
    .controller('appController', ["$scope", "$rootScope", "$cookies", "$localStorage", "Languages", appController]);

function appController($scope, $rootScope, $cookies, $localStorage, Languages) {

    $scope.$watch(function () {
        return $cookies.get("siteLanguage");
    }, function (newVal, oldVal) {
        getTexts();
    });

    var d = new Date();
    $rootScope.year = d.getFullYear();

    $rootScope.$on('loading:finish', function () {
        $scope.hideLoader();
    });

    $scope.hideLoader = function () {
        (!angular.element(document.querySelectorAll('#loading')).hasClass("ng-hide")) ? angular.element(document.querySelectorAll('#loading')).addClass("ng-hide") : angular.noop();
    };

    $scope.showLoader = function () {
        (angular.element(document.querySelectorAll('#loading')).hasClass("ng-hide")) ? angular.element(document.querySelectorAll('#loading')).removeClass("ng-hide") : angular.noop();
    };

    function getTexts() {
        Languages.get_texts($cookies.get("siteLanguage"))
            .then(function (response) {
                $localStorage.siteTexts = response.data;
            });
    };

}
