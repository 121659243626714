angular.module('mapleLeafApp')
    .controller('contactUsController', ["$scope", "$rootScope", "$localStorage", "$mdToast", "Contact", "vcRecaptchaService", "$cookies", "hdImage", "$sce", "recaptchaKey", contactUsController]);

function contactUsController($scope, $rootScope, $localStorage, $mdToast, Contact, vcRecaptchaService, $cookies, hdImage, $sce, recaptchaKey) {

    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        $('header').addClass('dark');
        $('.stripes').addClass('dark');
        $('.languages').addClass('dark');
        $('.logo-header').attr({
            'src': '/images/maple-leaf-diamonds-logo-254x71.png'
        });
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
        }
    });

    $scope.recaptchaKey = recaptchaKey;

    (function getTexts() {
        $scope.texts = $localStorage.siteTexts.contact;
        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

        if ($cookies.get("siteLanguage") == "en-ca" || $cookies.get("siteLanguage") == "en-us") {
            $scope.recaptchaLanguage = "en";
        } else {
            $scope.recaptchaLanguage = "fr-CA";
        }

    })();

    /** Contact controller variables **/
    if (!$rootScope.firstVersion) {
        $scope.user = {
            "language": $cookies.get("siteLanguage"),
            "name": "Efrain",
            "email": "efrain@ggamma.com",
            "phone": "416-555-1212",
            "storename": "some store name",
            "storeaddress": "some store address",
            "comments": "some comments",
            "DEBUG_TargetEmail": "efrain@ggamma.com"
        };
    }

    $scope.user = {
        "language": $cookies.get("siteLanguage"),
        "name": "",
        "email": "",
        "phone": "",
        "storename": "",
        "storeaddress": "",
        "comments": "",
        "DEBUG_TargetEmail": ""
    };

    $rootScope.contactLoader = false;
    $scope.response = null;
    $scope.widgetId = null;

    /**Validations for the repcatcha**/
    $scope.setResponse = function (response) {
        $scope.response = response;
    };

    $scope.setWidgetId = function (widgetId) {
        $scope.widgetId = widgetId;
    };

    $scope.cbExpiration = function () {
        vcRecaptchaService.reload($scope.widgetId);
        $scope.response = null;
    };

    /** Function to send the form **/
    $scope.send = function (params) {

        var valid = false;

        if ($scope.user.name == '' || $scope.user.email == '' || $scope.user.phone == '' || $scope.user.storename == '' || $scope.user.storeaddress == '' || $scope.user.comments == '') {
            $mdToast.show(
                $mdToast.simple()
                    .textContent($scope.texts.completeAll)
                    .position('bottom right')
                    .toastClass('md-warn')
            );
            return false;
        }

        if ($scope.response != null && $scope.response != "") {
            $rootScope.contactLoader = true;
            vcRecaptchaService.reload($scope.widgetId);
            valid = true;
        }

        if (valid == true) {
            setTimeout(function () {

                params.recaptcha_sitekey = recaptchaKey;
                params.recaptcha_response_field = $scope.response;

                Contact.sendForm(params)
                    .then(function successCallback(response) {
                        $rootScope.contactLoader = false;
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent($scope.texts.successMessage)
                                .position('bottom right')
                                .toastClass('md-success')
                        );
                        $scope.user = {
                            "language": $cookies.get("siteLanguage"),
                            "name": "",
                            "email": "",
                            "phone": "",
                            "storename": "",
                            "storeaddress": "",
                            "comments": "",
                            "DEBUG_TargetEmail": ""
                        };
                    }, function errorCallback(response) {
                        console.log(response);
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent(response.statusText)
                                .position('bottom right')
                                .toastClass('md-warn')
                        );
                    });

            }, 2000);

        } else {
            $mdToast.show(
                $mdToast.simple()
                    .textContent($scope.texts.recaptcha)
                    .position('bottom right')
                    .toastClass('md-warn')
            );
        }
    };
}
