angular.module('mapleLeafApp')
    .controller('privacyPolicyController', ["$scope", "$sce", "$localStorage", privacyPolicyController]);

function privacyPolicyController($scope, $sce, $localStorage) {
    $scope.showLoader();

    if ($("html").hasClass('fp-enabled')) {
        $rootScope.destroyDirective();
    }

    $scope.$on('$viewContentLoaded', function () {
        $('header').addClass('dark');
    });

    (function getTexts() {
        $scope.texts = $localStorage.siteTexts.privacyPolicy;
        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

    })();

}
