angular.module('mapleLeafApp')
    .controller('minesController', ["$scope", "$localStorage", "$routeParams", "$sce", minesController]);

function minesController($scope, $localStorage, $routeParams, $sce) {

    $scope.showLoader();

    (function getTexts() {
        $scope.mine = $routeParams.mine;

        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

        if ($scope.mine == "ekati") {
            $scope.texts = $localStorage.siteTexts.ekati;
        } else if ($scope.mine == "diavik") {
            $scope.texts = $localStorage.siteTexts.diavik;
        } else if ($scope.mine == "gahcho") {
            $scope.texts = $localStorage.siteTexts.gahcho;
        } else if ($scope.mine == "renard") {
            $scope.texts = $localStorage.siteTexts.renard;
        }

    })();

}
