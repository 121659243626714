angular.module('mapleLeafApp')
    .directive('sliderCollections', ["$window", "coronaURL", sliderCollections])

/** Directive for the height of the collections slides **/
function sliderCollections($window, coronaURL) {

    function link(scope, elem, attrs) {
        function resize() {
            var winHeight;
            /* if ($window.innerWidth < 768) {
            //     winHeight = ($window.innerHeight - $('header').height()) * 0.7;
            // } else if ($window.innerWidth < 1025 && $window.innerWidth >= 768) {
            //     winHeight = ($window.innerHeight - $('header').height()) * 0.7;
            // } else { */
                winHeight = ($window.innerHeight - $('header').height()) * 0.8;
            /* } */

            elem.css({
                "background-image": "url('" + coronaURL + scope.data + "')",
                "height": winHeight + "px"
            });
        }

        resize();

        angular.element($window).bind('resize', function () {
            resize();
        })
    }

    return {
        restrict: 'A',
        scope: {
            data: '=data'
        },
        link: link
    }

}