angular.module('mapleLeafApp')
    .directive('adjust', ["$window", adjust]);

/** Directive to adjust item to screen size **/
function adjust($window) {

    function link(scope, elem, attrs) {
        function adjust() {
            var winHeight = $window.innerHeight;
            var winWidth = $window.innerWidth;
            
            $('.imgContact').css({
                'min-height': winHeight + 'px'
            });
            
            if ($window.innerWidth > $window.innerHeight && $window.innerHeight >= 500) {
                elem.css({
                    'height': winHeight + 'px'
                });
            }
            
            if ($window.innerWidth > $window.innerHeight && $window.innerHeight < 500) {
                elem.css({
                    'min-height': winHeight + 'px',
                    'height': "auto"
                });
            }
            
            if ($window.innerWidth < $window.innerHeight) {
                elem.css({
                    'height': 'auto'
                });
            }
        }

        adjust();

        angular.element($window).bind('resize', function () {
            adjust();
        })
    }

    return {
        restrict: 'A',
        scope: {
            data: '@'
        },
        link: link
    }

}