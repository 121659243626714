angular.module('mapleLeafApp')
    .factory('Languages', ["$http", "$q", Languages]);

/** Lang Service **/
function Languages($http, $q) {

    return {

        get_texts: function (lang) {
            
            if (lang == '' || lang.length <= 0) {
                lang = 'en-ca';
            }
            
            if(lang == "en-ca") {
                return $http.get('/json/text-en-ca.json');
            } else if(lang == "en-us") {
                return $http.get('/json/text-en-us.json');
            } else {
                return $http.get('/json/text-fr-ca.json');
            }

        }

    }

}