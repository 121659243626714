angular.module('mapleLeafApp')
    .controller('collectionDetailController', ["$scope", "$rootScope", "$location", "Collections", "$window", "$cookies", "$routeParams", "coronaURL", "hdImage", "$localStorage", "$sce", "$timeout", collectionDetailController]);

function collectionDetailController($scope, $rootScope, $location, Collections, $window, $cookies, $routeParams, coronaURL, hdImage, $localStorage, $sce, $timeout) {

    $window.scrollTo(0, 0);
    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        $('header').addClass('dark');
        $('header').addClass('absolute');
        $('.stripes').addClass('dark');
        $('.languages').addClass('dark');
        $('.logo-header').attr({
            'src': '/images/maple-leaf-diamonds-logo-254x71.png'
        });

        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
        }
    });

    $scope.$on('$routeChangeStart', function () {
        $('header').removeClass('absolute');
        $scope.$on("$destroy", function () {
            angular.element($window).off('scroll', askBottom);
        });
    });

    $scope.coronaURL = coronaURL;
    $scope.noResultsText = '';
    $scope.backgroundImage = '';
    $scope.loader = true;

    $scope.items = [];
    $scope.itemsStart = 1;
    $scope.callingItems = false;
    $scope.stopCalling = false;

    (function getTexts() {
        $scope.texts = $localStorage.siteTexts.singleCollection;
        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

        $scope.search = $routeParams.search;

    })();

    var arrayID = 0;

    $scope.getItems = function (start) {

        $scope.callingItems = true;
        var catFilter = [];
        var shapeFilter = [];
        var styleFilter = [];
        var filtersCount = 0;

        if ($location.search().category != undefined) {
            var category = $location.search().category;
            category = category.split('-');
            for (var index = 0; index < category.length; index++) {
                var element = category[index].split(',');
                for (var indexx = 0; indexx < element.length; indexx++) {
                    catFilter.push(Number(element[indexx]));
                }
            }
        }

        if ($location.search().shape != undefined) {
            var shape = $location.search().shape;
            shape = shape.split('-');
            for (var index = 0; index < shape.length; index++) {
                var element = shape[index].split(',');
                for (var indexx = 0; indexx < element.length; indexx++) {
                    shapeFilter.push(Number(element[indexx]));
                }
            }
        }

        if ($location.search().style != undefined) {
            var style = $location.search().style;
            style = style.split('-');
            for (var index = 0; index < style.length; index++) {
                var element = style[index].split(',');
                for (var indexx = 0; indexx < element.length; indexx++) {
                    styleFilter.push(Number(element[indexx]));
                }
            }
        }

        var urlFilters = [];
        $scope.urlFilters = urlFilters = [{
            "collections": [
                Number($routeParams.collection)
            ],
            "styles": styleFilter,
            "shapes": shapeFilter,
            "categories": catFilter
        }];

        var params = {
            "language": $cookies.get("siteLanguage"),
            "filters": urlFilters,
            "search": "",
            "record_start": ($scope.items.length <= 0) ? 1 : $scope.items.length + 1,
            "record_count": 21,
            "device": $cookies.get("device")
        };

//        console.log(params);
        if (params.record_start != null) {
        	fbq('track', 'ViewContent', { "CollectionFilters" : urlFilters, "ItemStart" : params.record_start } );
            Collections.getItems(params)
                .then(function (response) {
                    if (response.data.items.length > 0) {
                        for (var index = 0; index < response.data.items.length; index++) {
                            var temp = response.data.items[index];
                            temp.arrayID = arrayID;
                            $scope.items.push(temp);
                            $scope.itemsStart = $scope.items.length + 1;
                            arrayID++;
                        }
                    } else {
                        $scope.stopCalling = true;
                        if ($scope.items.length <= 0) {
                            $scope.noResultsText = $scope.texts.noResults;
                        }
                    }

                    if (response.data.items.length < 21) {
                        $scope.stopCalling = true;
                    }
                    $scope.callingItems = false;
                    $scope.loader = true;
                }, function (response) {
                    console.log(response);
                    $scope.noResultsText = $scope.texts.noResults;
                    $scope.statusText = response.statusText;
                    $scope.callingItems = false;
                    $scope.stopCalling = true;
                    $scope.loader = true;
                });
        }

    };

    $scope.getItems($scope.itemsStart);

    function getCollections() {
        var params = {
            "language": $cookies.get("siteLanguage"),
            "device": $cookies.get("device")
        };

        Collections.getCollections(params)
            .then(function (response) {
                for (var index = 0; index < response.data.collections.length; index++) {
                    var element = response.data.collections[index];
                    if (Number(element.id) == Number($routeParams.collection)) {
                        $scope.collection = element;
                        $scope.backgroundImage = $scope.coronaURL + element.headerImage;
                    }
                }
            }, function (response) {
                console.log(response);
            });

    };
    getCollections();

    function askBottom() {
        if ($scope.stopCalling) return;

        if ($scope.callingItems) return;

        if (angular.element('infiniteScrollBottom').length > 0) {
            if (($window.pageYOffset + $window.innerHeight + 350) > angular.element('infiniteScrollBottom')[0].offsetTop) {
                $scope.loader = false;
                $scope.getItems($scope.itemsStart);
            }
        }
    }

    angular.element($window).bind('scroll mousewheel', function () {
        askBottom();
    });

    document.addEventListener('touchmove', function () {
        askBottom();
    });

    var url = $location.path().split('/collections/');
    $scope.currenPath = url[1];

    $scope.goToDetail = function (piece) {
        $localStorage.itemsList = $scope.items;
        $localStorage.filters = $scope.urlFilters;
        $localStorage.urlFilters = $location.absUrl();
        var url = "/collections/" + piece.collectionId + "/" + piece.id;
        $timeout(function () {
            $location.path(url);
        });
    };

}
