angular.module('mapleLeafApp')
    .controller('5cController', ["$scope", "$rootScope", "$localStorage", "$timeout", "$sce", fiveCsController]);

function fiveCsController($scope, $rootScope, $localStorage, $timeout, $sce) {

    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        $('.arrowBack').removeClass('none');
        angular.element('.phoneMenu').removeClass('open');
        if ($('header').hasClass('absolute')) {
            $('header').removeClass('absolute');
        }
    });

    $scope.$on('$routeChangeStart', function () {
        $rootScope.destroyDirective();
        $('.arrowBack').addClass('none');
    });

    (function getTexts() {
        $scope.texts = {
            "title": $localStorage.siteTexts.fivecs.title,
            "cutShape": {
                "title": $localStorage.siteTexts.fivecs.cutShape.title,
                "description": {
                    "paragraph1": $sce.trustAsHtml($localStorage.siteTexts.fivecs.cutShape.description.paragraph1),
                    "paragraph2": $sce.trustAsHtml($localStorage.siteTexts.fivecs.cutShape.description.paragraph2),
                    "paragraph3": $sce.trustAsHtml($localStorage.siteTexts.fivecs.cutShape.description.paragraph3),
                    "paragraph4": $sce.trustAsHtml($localStorage.siteTexts.fivecs.cutShape.description.paragraph4),
                }
            },
            "idealProportions": {
                "title": $localStorage.siteTexts.fivecs.idealProportions.title,
                "description": $sce.trustAsHtml($localStorage.siteTexts.fivecs.idealProportions.description)
            },
            "colour": {
                "title": $localStorage.siteTexts.fivecs.colour.title,
                "description": {
                    "paragraph1": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph1),
                    "paragraph2": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph2),
                    "paragraph3": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph3),
                    "paragraph4": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph4),
                    "paragraph5": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph5),
                    "paragraph6": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph6),
                    "paragraph7": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph7),
                    "paragraph8": $sce.trustAsHtml($localStorage.siteTexts.fivecs.colour.description.paragraph8)
                }
            },
            "clarity": {
                "title": $localStorage.siteTexts.fivecs.clarity.title,
                "description": {
                    "paragraph1": $sce.trustAsHtml($localStorage.siteTexts.fivecs.clarity.description.paragraph1),
                    "paragraph2": $sce.trustAsHtml($localStorage.siteTexts.fivecs.clarity.description.paragraph2),
                    "paragraph3": $sce.trustAsHtml($localStorage.siteTexts.fivecs.clarity.description.paragraph3),
                    "paragraph4": $sce.trustAsHtml($localStorage.siteTexts.fivecs.clarity.description.paragraph4),
                    "paragraph5": $sce.trustAsHtml($localStorage.siteTexts.fivecs.clarity.description.paragraph5),
                    "paragraph6": $sce.trustAsHtml($localStorage.siteTexts.fivecs.clarity.description.paragraph6)
                }
            },
            "carat": {
                "title": $localStorage.siteTexts.fivecs.carat.title,
                "description": {
                    "paragraph1": $sce.trustAsHtml($localStorage.siteTexts.fivecs.carat.description.paragraph1),
                    "paragraph2": $sce.trustAsHtml($localStorage.siteTexts.fivecs.carat.description.paragraph2)
                }
            },
            "canadian": {
                "title": $localStorage.siteTexts.fivecs.canadian.title,
                "description": {
                    "paragraph1": $sce.trustAsHtml($localStorage.siteTexts.fivecs.canadian.description.paragraph1),
                    "paragraph2": $sce.trustAsHtml($localStorage.siteTexts.fivecs.canadian.description.paragraph2),
                    "paragraph3": $sce.trustAsHtml($localStorage.siteTexts.fivecs.canadian.description.paragraph3)
                }
            }
        };
        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

        $timeout(function () {
            if ($("html").hasClass('fp-enabled')) {
                $rootScope.destroyDirective();
                $rootScope.loadAboutDirective();
            } else {
                $rootScope.loadAboutDirective();
            }
        });
    })();

}
