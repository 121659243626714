angular.module('mapleLeafApp')
    .directive('toggleMenu', ["$document", toggleMenu]);

/** Directive for toggle the mobile menu **/
function toggleMenu($document) {

    /** Directive link function **/
    function link(scope, element, attrs) {
        angular.element(element).bind('click', function (e) {
            menu = angular.element($document[0].querySelector('.' + scope.menu));
            if (angular.element(menu).hasClass('open')) {
                // $('html, body').removeClass('no-overflow'); */
                angular.element(menu).removeClass('open');
            } else {
                // $('html, body').addClass('no-overflow');       */              
                angular.element(menu).addClass('open');
            }

        });
    }

    return {
        restrict: 'A',
        scope: {
            'menu': '=toggleMenu'
        },
        link: link
    }
}