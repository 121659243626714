angular
    .module('mapleLeafApp')
    .config(["$routeProvider", "$locationProvider", config]);

function config($routeProvider, $locationProvider) {

    /** Routes **/

    $locationProvider.html5Mode(true).hashPrefix('/');

    $routeProvider
        .when('/', {
            controller: 'mainController',
            templateUrl: 'partials/home.html'
        })
        .when('/locator', {
            controller: 'locatorController',
            templateUrl: 'partials/locator.html'
        })
        .when('/tracking', {
            controller: 'trackingController',
            templateUrl: 'partials/tracking.html'
        })
        .when('/tracking/:anything', {
            resolve: {
                version: ['$rootScope', '$location', function ($rootScope, $location) {
                    $location.path('/tracking');
                }]
            }
        })
        .when('/collections', {
            controller: 'collectionController',
            templateUrl: 'partials/collection.html'
        })
        .when('/collections/:collection', {
            controller: 'collectionDetailController',
            templateUrl: 'partials/collectionDetail.html'
        })
        .when('/collections/:collection/:piece', {
            controller: 'pieceDetailController',
            templateUrl: 'partials/piece.html'
        })
        .when('/piece/:piece', {
            controller: 'pieceDetailController',
            templateUrl: 'partials/piece.html'
        })
        .when('/contact-us', {
            controller: 'contactUsController',
            templateUrl: 'partials/contact_us.html'
        })
        .when('/about', {
            resolve: {
                version: ['$location', function ($location) {
                    $location.path('/about/canadian-diamonds');
                }]
            }
        })
        .when('/about/precious-metals', {
            controller: 'preciousMetalsController',
            templateUrl: 'partials/precious_metals.html'
        })
        .when('/about/canadian-diamonds', {
            controller: 'canadianDiamondsController',
            templateUrl: 'partials/canadian_diamonds.html'
        })
        .when('/about/canadian-diamonds/:mine', {
            controller: 'minesController',
            templateUrl: 'partials/canadian_mines.html'
        })
        .when('/about/5c', {
            controller: '5cController',
            templateUrl: 'partials/5c.html'
        })
        .when('/about/certification', {
            controller: 'certificationController',
            templateUrl: 'partials/certification.html'
        })
        .when('/privacy-policy', {
            controller: 'privacyPolicyController',
            templateUrl: 'partials/privacyPolicy.html'
        })
        .when('/terms-and-conditions', {
            controller: 'termsConditionsController',
            templateUrl: 'partials/termsConditions.html'
        })
        .otherwise({
            redirectTo: '/'
        });

}