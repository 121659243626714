angular.module('mapleLeafApp')
    .directive('img', ["$timeout", "$http", img])
    .directive('bgImg', ["$interval", bgImg])
    .controller('imgController', ["$scope", "hdImage", imgController])
    .factory('hdImage', ["$http", "$q", "$window", hdImage]);
    
/** Directive to verify the resolution of the screen and to change the images **/
function img($timeout, $http) {

    var dataUrlRegex = /^data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/, allowedImageTypesRegex = /(png|jp[e]?g)$/, fadeInWhenLoaded = false;

    function link(scope, elem, attrs) {

        /* if(angular.isDefined(scope.replace) && !scope.replace) return angular.noop();

        // $timeout(function () {
        //     if ((scope.isRetina() || scope.isUHD()) && attrs.src !== undefined && elem[0].tagName === 'IMG' && attrs.src.match(allowedImageTypesRegex) && !attrs.src.match(dataUrlRegex)) {
        //         attrs.$observe('src', function(newImg, oldVal) {
        //             var newSrc = scope.verifyImg(newImg);
        //             if (!scope.msie()) {
        //                 var img = new Image();
        //                 img.onload = function(e) {
        //                     attrs.$set('src', newSrc);
        //                 };
        //                 img.onerror = function() {
        //                     attrs.$set('src', '/images/placeholder.jpg');                            
        //                 };
        //                 img.src = newSrc;
        //             } else {
        //                 var img = new Image();
        //                 img.onload = function() {
        //                     elem.prop('src', newSrc);                            
        //                 };
        //                 img.onerror = function() {
        //                     elem.prop('src', '/images/placeholder.jpg');                            
        //                 };
        //                 img.src = newSrc;
        //             }
        //         });
        //     }
        // });  */
    }

    return {
        scope: {
            replace: "=replace"
        },
        restrict: 'E',
        controller: 'imgController',
        link: link
    }

}

/** Directive to verify the resolution of the screen and to change background images **/
function bgImg($interval) {

    function link(scope, elem, attrs) {

        if(angular.isUndefined(scope.replace) || !scope.replace) return angular.noop();
        
        var stop;
        scope.askSrc = function () {
            stop = $interval(function () {

                if (elem[0].style.backgroundImage !== 'url("")') {
                    elem[0].style.backgroundImage = "url('" + scope.verifyImg(elem[0].style.backgroundImage, true) + "')";
                    scope.stopAsking();
                }

            }, 500);
        };

        if (scope.isRetina() || scope.isUHD()) {
            scope.askSrc();
        }

        scope.stopAsking = function () {
            if (angular.isDefined(stop)) {
                $interval.cancel(stop);
                stop = undefined;
            }
        };

    }

    return {
        scope: {
            replace: "=replace"
        },
        restrict: 'AC',
        controller: 'imgController',
        link: link
    }

}

function imgController($scope, hdImage) {

    $scope.verifyImg = function (src, isBg) {
        if(src.indexOf('@2x') >= 0 || src.indexOf('@3x') >= 0 || src.indexOf('placeholder') >= 0) return src;

        return hdImage.getImgUrl(src, isBg);
    }

    $scope.msie = function () {
        return hdImage.msie();
    }

    $scope.isUHD = function () {
        return hdImage.isUHD();
    }

    $scope.isRetina = function () {
        return hdImage.isRetina();        
    }

}

function hdImage($http, $q, $window) {

    return {

        msie: function () {
            var isIE = false || !!document.documentMode;
            var isEdge = !isIE && !!window.StyleMedia;
            return (isIE || isEdge) || false;
        },

        isMobile: function() {
            var ask = false;
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) ask = true;
            return ask; 
        },

        isUHD: function () {
            return (window.devicePixelRatio && window.devicePixelRatio >= 3);
        },

        isRetina: function () {
            return (window.devicePixelRatio && (window.devicePixelRatio >= 1 && window.devicePixelRatio < 3));
        },

        getImgUrl: function(src, isBg) {
            var extension = "";
            isBg = (isBg != undefined || false);

            if(isBg) {
                var path = src.split("url(\"");
                path = path[1];
                path = path.split("\")");
                src = path[0];            
            }
    
            for (var index = src.length; index >= 0; index--) {
                var element = src[index - 1];
                extension = element + extension;
                if (element === ".") {
                    break;
                }
            }

            var url = src.split(extension);
            if ((!url[0].indexOf("http") >= 0 || url[0].indexOf("https") >= 0) && !isBg) {
                url[0] = window.location.origin + url[0];
            }

            var suffix = "";
            if (this.isRetina()) {
                suffix = '@2x';
            } else if (this.isUHD()) {
                if(this.isMobile()) {
                    suffix = '@2x';
                } else {
                    suffix = '@2x';
                }
            }

            return url[0] + suffix + extension;
        }

    }

}