angular.module('mapleLeafApp')
    .factory('Items', ["$http", "coronaURL", "Collections", "$window", Items]);

/** Items Service **/
function Items($http, coronaURL, Collections, $window) {

    return {

        sendHint: function (params) {

            return $http({
                "url": coronaURL + "/SendPageReference/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });

        },

        getItemDetails: function (params) {

            return $http({
                "url": coronaURL + "/GetItemDetails/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });

        },

        moreInfo: function (params) {

            return $http({
                "url": coronaURL + "/GetMoreInformation/",
                "method": "POST",
                "headers": {
                    "content-type": "application/json"
                },
                "data": params
            });

        }

    }

}