angular.module('mapleLeafApp')
    .controller('filtersMenuController', ["$scope", "$rootScope", "$localStorage", "$route", "$location", "$window", "$cookies", "$routeParams", "$mdToast", "$timeout", "Items", "Collections", "recaptchaKey", filtersMenuController]);

function filtersMenuController($scope, $rootScope, $localStorage, $route, $location, $window, $cookies, $routeParams, $mdToast, $timeout, Items, Collections, recaptchaKey) {

    /** Variables and functions for Collections Submenu **/
    $scope.categories = null;
    $scope.styles = null;
    $scope.shapes = null;
    $scope.resultsChanged = '';
    $scope.appliedFilters = [];
    $scope.collections = [];
    $scope.categories = [];
    $scope.styles = [];
    $scope.shapes = [];
    $scope.selectedCollections = null;
    $scope.selectedCategories = [];
    $scope.selectedStyles = [];
    $scope.selectedShapes = [];
    $scope.filters = [];
    $scope.collectionsArray = [];
    $scope.categoriesArray = [];
    $scope.stylesArray = [];
    $scope.shapesArray = [];
    $scope.filtersChanged = false;
    $scope.countCollections = 0;
    $scope.countCategories = 0;
    $scope.countStyles = 0;
    $scope.countShapes = 0;
    $scope.backupFilters = null;

    var collectionsSearch = [],
        categoriesSearch = [],
        stylesSearch = [],
        shapesSearch = [];

    $scope.$on('$routeChangeStart', function ($ev, current, previous) {
        $scope.closeCollectionSubmenu();
    });

    (function getTexts() {
        $scope.filtersTexts = $localStorage.siteTexts.filtersMenu;
    })();

    $scope.closeCollectionSubmenu = function () {
        if ($window.innerWidth < 1024) {
            $scope.showCollections = false;
            $scope.showCat = false;
            $scope.showStyle = false;
            $scope.showShape = false;
        } else {
            $scope.showCollections = true;
            $scope.showCat = true;
            $scope.showStyle = true;
            $scope.showShape = true;
        }
        Collections.closeCollectionSubmenu();
        var urlFilters = checkFilters();

        $scope.selectedCollections = urlFilters.collection.items;
        $scope.countCollections = urlFilters.collection.count;

        $scope.selectedCategories = urlFilters.categories.items;
        $scope.countCategories = urlFilters.categories.count;

        $scope.selectedStyles = urlFilters.styles.items;
        $scope.countStyles = urlFilters.styles.count;

        $scope.selectedShapes = urlFilters.shapes.items;
        $scope.countShapes = urlFilters.shapes.count;
    };

    $scope.applyFilters = function () {
        $scope.resultsChanged = '';
        Collections.closeCollectionSubmenu();
        if ($scope.filtersChanged) {
            $scope.items = [];
            $scope.itemsStart = 0;
            search($scope.selectedCollections, $scope.selectedCategories, $scope.selectedStyles, $scope.selectedShapes);
        }
    };

    $rootScope.openCollectionSubmenu = function () {
        Collections.openCollectionSubmenu();
    };

    function setSearchUrls() {
        var urlSearch = $location.search();

        if (urlSearch.collections && urlSearch.collections != undefined)
            collectionsSearch = urlSearch.collections

        if (urlSearch.categories && urlSearch.categories != undefined)
            categoriesSearch = urlSearch.categories

        if (urlSearch.styles && urlSearch.styles != undefined)
            stylesSearch = urlSearch.styles

        if (urlSearch.shapes && urlSearch.shapes != undefined)
            shapesSearch = urlSearch.shapes

        var params = {
            "language": $cookies.get("siteLanguage"),
            "recaptcha_sitekey": recaptchaKey,
            "recaptcha_response_field": "000",
            "recaptcha_skip": "skiprecaptcha"
        };

        var filters = {};

        Collections.getFilters(params)
            .then(function (data) {
                filters = data.data;

                $scope.selectedCollections = [];
                $scope.selectedCategories = [];
                $scope.selectedStyles = [];
                $scope.selectedShapes = [];
                $scope.collectionsArray = [];
                $scope.categoriesArray = [];
                $scope.stylesArray = [];
                $scope.shapesArray = [];

                $scope.collections = filters.collections;
                $scope.categories = filters.categories;
                $scope.styles = filters.styles;
                $scope.shapes = filters.shapes;

                for (var i = 0; i < $scope.collections.length; i++) {
                    $scope.collectionsArray.push($scope.collections[i]);
                }

                if ($scope.categories.length > 0) {
                    for (var i = 0; i < $scope.categories.length; i++) {
                        $scope.categoriesArray.push($scope.categories[i]);
                    }
                }

                if ($scope.styles.length > 0) {
                    for (var i = 0; i < $scope.styles.length; i++) {
                        $scope.stylesArray.push($scope.styles[i]);
                    }
                }

                if ($scope.shapes.length > 0) {
                    for (var i = 0; i < $scope.shapes.length; i++) {
                        $scope.shapesArray.push($scope.shapes[i]);
                    }
                }

                var urlFilters = checkFilters();

                $scope.selectedCollections = urlFilters.collection.items;
                $scope.countCollections = urlFilters.collection.count;

                $scope.selectedCategories = urlFilters.categories.items;
                $scope.countCategories = urlFilters.categories.count;

                $scope.selectedStyles = urlFilters.styles.items;
                $scope.countStyles = urlFilters.styles.count;

                $scope.selectedShapes = urlFilters.shapes.items;
                $scope.countShapes = urlFilters.shapes.count;

                if (urlFilters.count > 0) {
                    angular.element('.number')[0].innerText = "(" + urlFilters.count + ")";
                }

            }, function (data) {
                console.log(data.statusText);
                $mdToast.show(
                    $mdToast.simple()
                        .textContent(data.statusText)
                        .position('bottom right')
                        .toastClass('md-warn')
                );
                return false;
            });

    };
    setSearchUrls();

    function checkFilters() {
        var collections = [],
            categories = [],
            styles = [],
            shapes = [],
            countCollections = 0,
            countCategories = 0,
            countStyles = 0,
            countShapes = 0;

        if ($routeParams.collection != undefined) {
            $.each($scope.collections, function (ind, ele) {
                if (ele.id == $routeParams.collection) {
                    collections.push(ele.name);
                    countCollections++;
                }
            });
        } else if ($routeParams.collections != undefined) {
            var params = $routeParams.collections.split('-');

            $.each(params, function (i, e) {
                $.each($scope.collections, function (ind, ele) {
                    if (ele.id == e) {
                        collections.push(ele.name);
                        countCollections++;
                    }
                });
            });
        }

        if ($routeParams.category != undefined) {
            var params = $routeParams.category.split('-');
            $.each($scope.categories, function (ind, ele) {
                $.each(params, function (i, e) {
                    if (ele.id == e) {
                        categories.push(ele.name);
                        countCategories++;
                    }
                });
            });
        }

        if ($routeParams.style != undefined) {
            var params = $routeParams.style.split('-');

            $.each($scope.styles, function (ind, ele) {
                $.each(params, function (i, e) {
                    if (ele.id == e) {
                        styles.push(ele.name);
                        countStyles++;
                    }
                });
            });
        }

        if ($routeParams.shape != undefined) {
            var params = $routeParams.shape.split('-');

            $.each($scope.shapes, function (ind, ele) {
                $.each(params, function (i, e) {
                    if (ele.id == e) {
                        shapes.push(ele.name);
                        countShapes++;
                    }
                });
            });
        }

        var data = {
            "collection": {
                "items": collections,
                "count": countCollections,
            },
            "categories": {
                "items": categories,
                "count": countCategories,
            },
            "styles": {
                "items": styles,
                "count": countStyles,
            },
            "shapes": {
                "items": shapes,
                "count": countShapes,
            },
            "count": countCollections + countCategories + countStyles + countShapes
        }

        return data;
    };

    $scope.toggle = function (item, list, filter) {
        $scope.filtersChanged = true;
        var idx;

        if (filter == 'collection') {
            idx = $scope.selectedCollections.indexOf(item);
            if (idx > -1) {
                $scope.selectedCollections.splice(idx, 1);
                $scope.countCollections--;
            } else {
                $scope.selectedCollections.push(item);
                $scope.countCollections++;
            }
        }

        if (filter == 'category') {
            idx = $scope.selectedCategories.indexOf(item);
            if (idx > -1) {
                $scope.selectedCategories.splice(idx, 1);
                $scope.countCategories--;
            } else {
                $scope.selectedCategories.push(item);
                $scope.countCategories++;
            }
        }

        if (filter == 'style') {
            idx = $scope.selectedStyles.indexOf(item);
            if (idx > -1) {
                $scope.selectedStyles.splice(idx, 1);
                $scope.countStyles--;
            } else {
                $scope.selectedStyles.push(item);
                $scope.countStyles++;
            }
        }

        if (filter == 'shape') {
            idx = $scope.selectedShapes.indexOf(item);
            if (idx > -1) {
                $scope.selectedShapes.splice(idx, 1);
                $scope.countShapes--;
            } else {
                $scope.selectedShapes.push(item);
                $scope.countShapes++;
            }
        }

    };

    $scope.exists = function (item, list) {
        return list.indexOf(item) > -1;
    };

    $scope.toggleAll = function (filter) {
        $scope.filtersChanged = true;

        if (filter == 'collections') {
            if ($scope.selectedCollections.length >= 0) {
                if ($scope.selectedCollections.length < $scope.collectionsArray.length) {
                    var tempArray = [];
                    $.each($scope.collections, function (i, e) {
                        tempArray.push(e.name);
                    });
                    $scope.selectedCollections = tempArray;
                } else {
                    $scope.selectedCollections = [];
                }
            }
        }

        if (filter == 'categories') {
            if ($scope.selectedCategories.length >= 0) {
                if ($scope.selectedCategories.length < $scope.categoriesArray.length) {
                    var tempArray = [];
                    $.each($scope.categories, function (i, e) {
                        tempArray.push(e.name);
                    });
                    $scope.selectedCategories = tempArray;
                } else {
                    $scope.selectedCategories = [];
                }
            }
        }

        if (filter == 'styles') {
            if ($scope.styles.length >= 0) {
                if ($scope.selectedStyles.length < $scope.stylesArray.length) {
                    var tempArray = [];
                    $.each($scope.styles, function (i, e) {
                        tempArray.push(e.name);
                    });
                    $scope.selectedStyles = tempArray;
                } else {
                    $scope.selectedStyles = [];
                }
            }
        }

        if (filter == 'shapes') {
            if ($scope.selectedShapes.length >= 0) {
                if ($scope.selectedShapes.length < $scope.shapesArray.length) {
                    var tempArray = [];
                    $.each($scope.shapes, function (i, e) {
                        tempArray.push(e.name);
                    });
                    $scope.selectedShapes = tempArray;
                } else {
                    $scope.selectedShapes = [];
                }
            }
        }

    };

    $scope.filterChanged = function () {
        $scope.filtersChanged = true;
    };

    function search(collection, category, styles, shapes) {
        var urlSearch = '';
        var collectionSearch = '';
        var categorySearch = '';
        var shapeSearch = '';
        var stylesSearch = '';
        var contCollections = 0;

        if (collection.length > 0) {
            for (var index = 0; index < collection.length; index++) {
                var collectionSlug = getSlug(collection[index], 'collection');
                if (collectionSearch == '') {
                    collectionSearch = collectionSearch + collectionSlug;
                } else {
                    collectionSearch = collectionSearch + '-' + collectionSlug;
                }
                contCollections++;
            }

            if (contCollections === 1) {
                collectionSearch = 'collections/' + collectionSearch;
            } else {
                collectionSearch = 'collections=' + collectionSearch;
            }
        }

        if (category.length > 0) {
            for (var index = 0; index < category.length; index++) {
                var categorySlug = getSlug(category[index], 'category');
                if (categorySearch == '') {
                    categorySearch = categorySearch + categorySlug;
                } else {
                    categorySearch = categorySearch + '-' + categorySlug;
                }
            }
            categorySearch = 'category=' + categorySearch;
        }

        if (styles.length > 0) {
            for (var index = 0; index < styles.length; index++) {
                var styleSlug = getSlug(styles[index], 'style');
                if (stylesSearch == '') {
                    stylesSearch = stylesSearch + styleSlug;
                } else {
                    stylesSearch = stylesSearch + '-' + styleSlug;
                }
            }
            stylesSearch = 'style=' + stylesSearch;
        }

        if (shapes.length > 0) {
            for (var index = 0; index < shapes.length; index++) {
                var shapeSlug = getSlug(shapes[index], 'shape');
                if (shapeSearch == '') {
                    shapeSearch = shapeSearch + shapeSlug;
                } else {
                    shapeSearch = shapeSearch + '-' + shapeSlug;
                }
            }
            shapeSearch = 'shape=' + shapeSearch;
        }

        var urlCollectionID = '';

        if (collectionSearch.length > 0) {
            if (contCollections === 1) {
                urlCollectionID = '/' + collectionSearch;
            } else {
                urlSearch = urlSearch + collectionSearch + '&';
            }
        }

        if (categorySearch.length > 0) {
            urlSearch = urlSearch + categorySearch + '&';
        }

        if (stylesSearch.length > 0) {
            urlSearch = urlSearch + stylesSearch + '&';
        }

        if (shapeSearch.length > 0) {
            urlSearch = urlSearch + shapeSearch;
        }

        if (urlSearch != '' || urlCollectionID != '') {
            if (contCollections !== 1) {
                urlSearch = '?' + urlSearch;
            } else {
                urlSearch = urlCollectionID + '?' + urlSearch;
            }
        }

        var lastString = urlSearch.charAt(urlSearch.length - 1);
        if (lastString == '&') {
            urlSearch = urlSearch.substring(0, urlSearch.length - 1);
        }

        var url = '';
        url = url + urlSearch;
        if (contCollections !== 1) {
            $window.location.href = 'collections' + url;
        } else {
            $window.location.href = url;
        }
    };

    function getSlug(name, filter) {
        var temp;
        if (filter == 'collection') {
            temp = $scope.collections;
        } else if (filter == 'category') {
            temp = $scope.categories;
        } else if (filter == 'style') {
            temp = $scope.styles;
        } else if (filter == 'shape') {
            temp = $scope.shapes;
        }

        var slug;

        $.each(temp, function (i, e) {
            if (e.name == name) {
                slug = e.id;
            }
        });

        return slug;
    };

    function showFilterItems() {
        if ($window.innerWidth <= 959) {
            hideAllFilters();
        } else {
            $timeout(function () {
                $scope.showCollections = true;
                $scope.showCat = true;
                $scope.showStyle = true;
                $scope.showShape = true;
            });
        }
    };

    function hideAllFilters() {
        $timeout(function () {
            $scope.showCollections = false;
            $scope.showCat = false;
            $scope.showStyle = false;
            $scope.showShape = false;
        });
    };

    showFilterItems();

    angular.element($window).bind('resize', function () {
        showFilterItems();
    });

    $scope.clearAllCheckbox = function () {
        $scope.resultsChanged = '';
        $scope.filtersChanged = true;
        $scope.selectedCollections = [];
        $scope.selectedCategories = [];
        $scope.selectedStyles = [];
        $scope.selectedShapes = [];
        $scope.countCollections = 0;
        $scope.countCategories = 0;
        $scope.countStyles = 0;
        $scope.countShapes = 0;

        /* search(undefined, $scope.selectedCategories, $scope.selectedStyles, $scope.selectedShapes); */

    };

    $scope.showFilter = function (item) {
        if ($window.innerWidth < 959) {
            if (item == 'collection') {
                $scope.showCollections = !$scope.showCollections;
            } else if (item == 'category') {
                $scope.showCat = !$scope.showCat;
            } else if (item == 'style') {
                $scope.showStyle = !$scope.showStyle;
            } else if (item == 'shape') {
                $scope.showShape = !$scope.showShape;
            }
        }
    };

}
