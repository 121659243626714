angular.module('mapleLeafApp')
    .controller('canadianDiamondsController', ["$scope", "$rootScope", "$localStorage", "$cookies", "$sce", "$interval", canadianDiamondsController]);

function canadianDiamondsController($scope, $rootScope, $localStorage, $cookies, $sce, $interval) {

    $scope.showLoader();

    $scope.$on('$viewContentLoaded', function () {
        if ($("html").hasClass('fp-enabled')) {
            $rootScope.destroyDirective();
            $rootScope.loadAboutDirective();
        } else {
            $rootScope.loadAboutDirective();
        }
        $('.arrowBack').removeClass('none');
        if ($('header').hasClass('absolute')) {
            $('header').removeClass('absolute');
        }
    });

    function loadDirective() {
        if (!$("html").hasClass('fp-enabled')) {
            $rootScope.loadAboutDirective();
        } else {
            $rootScope.destroyDirective();
            $scope.setInterval();
        }
    };

    $scope.$on('$routeChangeStart', function () {
        $rootScope.destroyDirective();
        $('.arrowBack').addClass('none');
    });

    $scope.$watch(function () {
        return $cookies.get("fullPageLoaded");
    }, function (newVal, oldVal) {
        if (angular.isDefined(newVal)) {
            if (newVal == "true") {
                $rootScope.goToSection($cookies.get("scrollID"));
            }
        }
    });

    (function getTexts() {

        $scope.texts = {
            "title": $sce.trustAsHtml($localStorage.siteTexts.canadianDiamonds.title),
            "exquisite": {
                "title": $sce.trustAsHtml($localStorage.siteTexts.canadianDiamonds.title),
                "description": {
                    "paragraph1": $sce.trustAsHtml($localStorage.siteTexts.canadianDiamonds.exquisite.description.paragraph1),
                    "paragraph2": $localStorage.siteTexts.canadianDiamonds.exquisite.description.paragraph2,
                    "paragraph3": $sce.trustAsHtml($localStorage.siteTexts.canadianDiamonds.exquisite.description.paragraph3)
                }
            },
            "formation": {
                "title": $localStorage.siteTexts.canadianDiamonds.formation.title,
                "imgTitle": $localStorage.siteTexts.canadianDiamonds.formation.imgTitle,
                "description": {
                    "paragraph1": $localStorage.siteTexts.canadianDiamonds.formation.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.canadianDiamonds.formation.description.paragraph2,
                    "paragraph3": $localStorage.siteTexts.canadianDiamonds.formation.description.paragraph3,
                    "paragraph4": $localStorage.siteTexts.canadianDiamonds.formation.description.paragraph4
                }
            },
            "discovery": {
                "title": $localStorage.siteTexts.canadianDiamonds.discovery.title,
                "description": {
                    "paragraph1": $localStorage.siteTexts.canadianDiamonds.discovery.description.paragraph1,
                    "paragraph2": $localStorage.siteTexts.canadianDiamonds.discovery.description.paragraph2,
                    "paragraph3": $localStorage.siteTexts.canadianDiamonds.discovery.description.paragraph3,
                    "paragraph4": $localStorage.siteTexts.canadianDiamonds.discovery.description.paragraph4,
                    "paragraph5": $localStorage.siteTexts.canadianDiamonds.discovery.description.paragraph5,
                }
            },
            "exploration": {
                "title": {
                    "part1": $localStorage.siteTexts.canadianDiamonds.exploration.title.part1,
                    "part2": $localStorage.siteTexts.canadianDiamonds.exploration.title.part2
                },
                "description": $sce.trustAsHtml($localStorage.siteTexts.canadianDiamonds.exploration.description)
            }
        };

        $scope.footerTexts = {
            "socials": $localStorage.siteTexts.footer.socials,
            "copyright": $localStorage.siteTexts.footer.copyright,
            "contact": $localStorage.siteTexts.footer.contact,
            "privacy": $localStorage.siteTexts.footer.privacy,
            "flashSite": $localStorage.siteTexts.footer.flashSite,
            "terms": $sce.trustAsHtml($localStorage.siteTexts.footer.terms)
        };

    })();

}
