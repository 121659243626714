angular.module('mapleLeafApp')
    .factory('Mines', ["$http", "$sce", Mines]);

function Mines($http, $sce) {

    return {

        mines: function () {

            return [
            {
                title: 'Ekati',
                slug: 'ekati',
                content: [
                    $sce.trustAsHtml('<strong>The Ekati Diamond Mine</strong> is Canada’s first surface and underground mine. Ekati started full production in October 1998. Ekati’s rich deposits were discovered by Canadian geologists Charles Fipke and Stuart Blusson in 1991, and are now mined by Dominion Diamond Corporation. Both Ekati & Diavik mines are located in the Lac de Gras region in the remote Arctic tundra 300 kilometres northeast of Yellowknife and 200 kilometres south of the Arctic Circle. It is accessible by air only, except during a three-month period in winter, when a 400-kilometre ice road allows trucks to supply the site.'),
                    'The Ekati Diamond Mine adheres to some of the world’s most stringent environmental standards. In addition to the regulatory requirements, an Environmental Agreement was negotiated with the governments of Canada and Northwest Territories to address environmental concerns that were not covered by existing legislation and regulations. The result is some of the world’s cleanest, most beautiful diamonds, mined in a socially and environmentally responsible manner.',
                    'The Ekati mine is renowned for the premium gem quality diamonds it produces. The most valuable gem quality rough diamond from the Ekati diamond mine was discovered in 2010. It is the 78 carat Ekati Spirit which was sold in 2011 for over 6 million dollars.'
                ]
            },
            {
                title: 'Renard',
                slug: 'renard',
                content: [
                    $sce.trustAsHtml('<strong>Renard</strong>, the French word for fox, is located approximately 350 km north of Chibougamau in the James Bay region of north-central Québec. Diamond exploration began on what was originally called the Foxtrot property in 1996. Five years later, partners Ashton Mining Canada and Soquem, had discovered and drilled five kimberlites. Construction began on July 10, 2014 and it is now, the mine is the first diamond mine in Québec, and one of only five operating in Canada. The project benefits from a large and growing resource, good mining conditions, strong social acceptance, a modest environmental footprint, and the development of direct-to-mine road infrastructure.'),
                    'An important aspect of the Renard Mine, which none of the other operating diamond mines in Canada has is the Route 167 extension, allowing all-season access to Renard by way of the Chibougamau and Mistissini communities and avoids the reliance other mines have on the ice roads.',
                    'Now in commercial production, the mine is expected to produce an average of 1.6 million carats per year over an initial 14-year mine life, representing approximately 2% of global supply. First ore was delivered to the plant approximately 10 weeks ahead of schedule on July 15, 2016. Commercial production was declared on January 1, 2017.',
                    'The Renard Project was constructed under the terms of the March 2012 Mecheshoo Agreement. The Mecheshoo Agreement provides training, employment and business opportunities for Crees during project construction, operation and closure, as well as setting out social, cultural and environmental principles under which the mine will be managed and respected. The Mecheshoo Agreement also includes a mechanism by which the Cree parties will financially benefit from the success of the project on a long-term basis, consistent with the mining industry’s best practices for engagement with First Nations communities.'
                ]
            },
            {
                title: 'Diavik',
                slug: 'diavik',
                content: [
                    $sce.trustAsHtml('<strong>The Diavik Diamond Mine</strong> is Canada’s largest diamond mine in terms of carat production. Diavik was established following the discovery of four diamond-bearing deposits, called kimberlite pipes, in 1994 and 1995 and began production in January 2003. The Diavik mine is located 300km North East of Yellowknife and at its outermost borders is only 30 km from the Ekati diamond mine.'),
                    'The Diavik mine is 40% owned by Dominion Diamond Corporation and 60% by RioTinto. In Diavik’s lifespan, which is estimated to take the production to 2023, it is expected to yield and estimated total of 90 million carats of rough diamonds, reaching annually 6-7 million carats of mostly gem-quality diamonds.',
                    'Diavik adheres to some of the world’s most rigorous environmental standards, its environmental management system is ISO 14001:2004 certified, and includes conspicuous protection for caribou, other wildlife and fish habitats.',
                    'Diavik’s exceptional production makes it one of the most valuable diamond mines in the world.'
                ]
            },
            {
                title: 'Gahcho Kué',
                slug: 'gahcho',
                content: [
                    $sce.trustAsHtml('Billed as the largest and richest new mine in the world in 2016 <strong>Gahcho Kué diamond mine</strong> is located on federal land in Canada\'s Northwest Territories.. The mine is on a 10,353 acre site located at Kennady Lake, approximately 280 kilometres northeast of Yellowknife in the Northwest Territories. The Gahcho Kué Mine is a joint venture between De Beers Canada Inc. (51%) and Mountain Province Diamonds Inc. (49%).'),
                    'Gahcho Kué, which means ‘place of the big rabbits or hares’ in the local Chipewyan language, began in 1995 when Mountain Province discovered the first kimberlite pipe, known as 5034. Three other pipes were discovered by De Beers Exploration two years later, with two of them, the Hearne and Tuzo kimberlites, having excellent economic potential. Extensive drilling and analysis followed and environmental permits were sought and granted for 5034, Hearne and Tuzo. Gahcho Kué is an open pit operation, mining three kimberlite pipes in sequence: 5034, Hearne and Tuzo. The mine is under full operation as of the fall of 2016 with about 500 employees and contractors. Unless more pipes are brought online the mine life is estimated to be 12 years. Both mine owners are committed to sustainable development in local communities and have signed six Impact Benefit Agreements (IBA) for the Gahcho Kué Mine. De Beers, as the mine operator, is committed to operating this new mine to high safety standards and with deep respect for the land. The total cost to open the mine is approximately C$1 billion.'
                ]
            }
            ];

        },

        get_mine: function (mine) {

            var mines = this.mines();
            for (var index = 0; index < mines.length; index++) {
                var tempMine = mines[index];
                if (tempMine.slug == mine) {
                    return tempMine;
                }
            }

        }

    }

}